import { Injectable } from '@angular/core';
import { SubscriptionForPollingGQL, SubscriptionStatus } from '@hxp/graphql';
import { Subscription, filter, map, takeWhile } from 'rxjs';
import { TranslatedToastService } from '@hxp/shared/i18n';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionStatusPollingService {
  private readonly pollInterval = 10000;
  private readonly _pollingSubscriptions = new Map<string, Subscription>();

  constructor(
    private _subscriptionOverviewQuery: SubscriptionForPollingGQL,
    private readonly _translatedToastService: TranslatedToastService,
  ) {}

  startPollingForSubscription(subscriptionId: string) {
    const pollingSubscription = this._pollingSubscriptions.get(subscriptionId) || this._getSubscriptionQueryForPolling(subscriptionId);
    if (pollingSubscription && pollingSubscription.closed) {
      return;
    }
    this._pollingSubscriptions.set(subscriptionId, pollingSubscription);
  }

  private _getSubscriptionQueryForPolling(id: string) {
    const subscriptionOverviewQueryRef = this._subscriptionOverviewQuery.watch({ id }, { pollInterval: this.pollInterval });

    return subscriptionOverviewQueryRef.valueChanges
      .pipe(
        filter((res) => !!res.data),
        takeWhile((res) => res.data.subscriptionById?.status !== SubscriptionStatus.Active, true),
        map((res) => {
          if (!res.data.subscriptionById) {
            throw new Error('Subscription not found');
          }
          return res.data.subscriptionById;
        }),
      )
      .subscribe({
        next: (subscriptionById) => {
          if (subscriptionById?.status === SubscriptionStatus.Active) {
            this._translatedToastService.success(
              'nucleus.services.subscription-status-polling-service.subscription-activation-success-message',
              { subscriptionTitle: subscriptionById?.title },
            );
          }
        },
        complete: () => {
          this._pollingSubscriptions.delete(id);
        },
        error: (_err) => {
          this._translatedToastService.error('nucleus.services.subscription-status-polling-service.subscription-activation-error-message', {
            subscriptionId: id,
          });
          this._pollingSubscriptions.delete(id);
        },
      });
  }
}
