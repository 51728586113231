import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

/**
 * Retrieves a parameter by name from an activated route. The resulting observable
 * emits values, if and only if, the next Params object contains the requested parameter and
 * only emits distinct values, if the source observable emits the same parameter value multiple
 * times in a row, only the first value will be emitted.
 *
 * @param parameterName The name of the route parameter
 */
export const routeParameter = <TReturn>(parameterName: string) => (
  routeParams$: Observable<Params>,
): Observable<TReturn> =>
  routeParams$.pipe(
    filter((p) => parameterName in p),
    map((p) => p[parameterName]),
    distinctUntilChanged(),
  );
