<ng-container *transloco="let t; read: 'users.account-access.user-rights'">
  <mat-card class="flex h-full flex-col border-0 rounded-none p-6">
    <mat-card-content class="grow overflow-auto p-0">
      <div class="h-full flex flex-col">
        <div class="mb-6">
          <h2 class="m-0" *ngIf="!isLoading; else ghost">{{ accountAppName }} {{ t('header') }}</h2>
          <ng-template #ghost>
            <hy-ghost-block class="h-[35px] w-1/12 block" />
          </ng-template>
        </div>

        <mat-card appearance="outlined" class="border-none p-0 flex flex-grow flex-col">
          <ng-container *ngIf="roleMappings$ | async as roles;">
            <hxp-admin-users-role-mappings
              header="{{ t('card-header') }}"
              [roleMappings]="roles"
              [appKey]="appKey"
              [loading]="isLoading"
              (roleEdit)="editUserRights($event)"
            />
          </ng-container>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
