import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, of, switchMap, tap } from 'rxjs';
import { FeatureFlagResponse, FlagAdditionalContext, FlagContext, Flags } from '../../models/feature-flag';
import { isSuccessResponse } from '../../utils/feature-flag-type-guards.utils';
import { FeatureFlagStrategy } from '../feature-flag.strategy';
import { FeatureFlagsContextBuilderService } from '../feature-flags-context-builder/feature-flags-context-builder.service';

@Injectable()
export class FeatureFlagStrategyServer extends FeatureFlagStrategy {
  private readonly _http = inject(HttpClient);
  private readonly _flagsContextBuilder = inject(FeatureFlagsContextBuilderService);
  private readonly _cachedFlags: Map<string, FeatureFlagResponse> = new Map();

  getFlag(flagKey: string, context?: FlagAdditionalContext) {
    if (this._cachedFlags.has(flagKey)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return of(this._cachedFlags.get(flagKey)!);
    }

    return this._flagsContextBuilder.getFlagContext(context).pipe(
      switchMap((context) => this._getFlagValueBasedOnContext(flagKey, context)),
      tap((flag) => {
        if (isSuccessResponse(flag)) {
          this._cachedFlags.set(flagKey, flag);
        }
      }),
    );
  }

  getAllFlags(flags: string[]) {
    return this._flagsContextBuilder
      .getFlagContext()
      .pipe(switchMap((context) => this._http.post<Flags>('/feature-flag/all', { context, flags })));
  }

  private _getFlagValueBasedOnContext(flagKey: string, context: FlagContext) {
    return this._http
      .post<FeatureFlagResponse>('/feature-flag', { flagKey, context })
      .pipe(catchError(() => of({ error: 'request failed' })));
  }
}
