<ng-container *transloco="let t; read: 'users.users-components.add-internal-user-dialog'">
  <div class="wizard">
    <hy-dialog
      #dialog
      class="flex flex-col overflow-y-auto"
      header="{{ t('header') }}"
      (confirmed)="onConfirmed()"
      [confirmLabel]="wizard && wizard._getFocusIndex() !== wizardSteps.UserInformation ? t('create-button-text') : t('next-button-text')"
      (dismissed)="closeDialog()"
      [dismissLabel]="t('cancel-button-text')"
      [valid]="!submitting"
    >
      <mat-horizontal-stepper hyWizardVertical linear [stepsAlignment]="'start'">
        <mat-step [label]="t('user-information-step-label')" [stepControl]="userForm.form">
          <app-user-form
            #userForm
            [userInformation]="defaultUserInformation"
            [confirmEmail]="true"
            (formValueChanged)="userInformationFormValueChanged($event)"
          ></app-user-form>
        </mat-step>
        <mat-step [label]="t('user-groups-step-label')" optional>
          <hxp-kernel-submitting-overlay [submitting]="submitting">
            <app-user-groups-list
              [allUserGroups]="allGroups$ | async"
              [userGroups]="sortedUserGroupsForUser"
              [isLoading]="groupsLoading"
              (assignedGroupsChanges)="assignedUserGroupsChanged($event)"
            ></app-user-groups-list>
          </hxp-kernel-submitting-overlay>

          <mat-progress-bar class="mb-2" [ngClass]="{ invisible: !submitting }"></mat-progress-bar>
        </mat-step>

        <!-- Edit icon override -->
        <ng-template matStepperIcon="edit">
          <mat-icon hyIcon>check</mat-icon>
        </ng-template>
        <!-- Done icon override -->
        <ng-template matStepperIcon="done">
          <mat-icon hyIcon>check</mat-icon>
        </ng-template>
      </mat-horizontal-stepper>
    </hy-dialog>
  </div>
</ng-container>
