import { skipWhile, take } from 'rxjs/operators';
import { Configuration } from './configuration';
import { ConfigurationService } from './configuration.service';

export function guaranteeConfigurationExists(configService: ConfigurationService): Promise<Configuration> {
    return new Promise<any>((resolve, reject) => {
        configService.configUpdate$
        // don't emit until config provider has a value
       .pipe(skipWhile(config => !config), take(1))
        // finish.
       .subscribe(resolve, reject);
    });
}
