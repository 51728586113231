import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { KernelComponentsModule } from '@hxp/kernel';
import { HyMaterialModule } from '@hyland/ui';

@Component({
  standalone: true,
  selector: 'hxp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatCardModule, HyMaterialModule, KernelComponentsModule],
})
export class CardComponent {}
