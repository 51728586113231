import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { HyGhostModule, HyMaterialModule, HyTranslateModule } from '@hyland/ui';

import { MatDividerModule } from '@angular/material/divider';
import { DashboardBannerComponent } from './components/dashboard-banner/dashboard-banner.component';
import { SubscriptionCardsComponent } from './components/subscription-cards/subscription-cards.component';

@NgModule({
  declarations: [SubscriptionCardsComponent],
  exports: [SubscriptionCardsComponent, DashboardBannerComponent],
  imports: [
    CommonModule,
    HyMaterialModule,
    HyGhostModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    RouterModule,
    HyTranslateModule,
    MatDividerModule,
    DashboardBannerComponent,
  ],
})
export class DashboardModule {}
