import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountAppListGQL } from '@hxp/graphql';
import { HyTranslateService } from '@hyland/ui';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

export interface AccountApp {
  id: string;
  name: string;
}

@Component({
  templateUrl: './account-app-list.view.html',
  styleUrls: ['./account-app-list.view.scss'],
})
export class AccountAppListView implements OnInit, OnDestroy {
  accountApps$: Observable<AccountApp[]>;
  selectedAppId: string | undefined;
  private readonly _unsubscribe$ = new Subject<void>();

  readonly loading = signal(false);

  constructor(
    private readonly _title: Title,
    private readonly _appsService: AccountAppListGQL,
    private readonly _translate: HyTranslateService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
  ) {
    this.accountApps$ = this._appsService.watch(undefined, { useInitialLoading: true }).valueChanges.pipe(
      map((resp) => {
        this.loading.set(resp.loading);

        if (!resp.data) {
          return [];
        }

        return resp.data.currentUser.homeAccount.account.accountApps.map((a) => {
          return { id: a.id, name: a.app.localizedName } as AccountApp;
        });
      }),
    );
  }

  ngOnInit() {
    this._title.setTitle(this._translate.get('nucleus.page-title.account-access'));
    this.trackSelectedApp();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private trackSelectedApp() {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap(() => this._route.firstChild!.paramMap),
        map((p) => p.get('id') || ''),
        distinctUntilChanged(),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((selectedAppId) => (this.selectedAppId = selectedAppId));
  }
}
