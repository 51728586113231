import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HyDialogModule } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, HyDialogModule, MatDialogModule, TranslocoModule],
  templateUrl: './unsaved-changes-process.dialog.html',
  styleUrls: ['./unsaved-changes-process.dialog.scss'],
})
export class UnsavedChangesProcessDialog {}
