import { Injectable } from '@angular/core';
import {
  CreateUserGroupGQL,
  EditUserGroupGQL,
  UserGroupCreateInput,
  UserGroupEditInput,
  UserGroupInformationGQL,
  UserGroupsDocument,
  UserGroupsGQL,
} from '@hxp/graphql';
import { filter, map } from 'rxjs/operators';
import { isMutationWithData } from '../../utils/is-mutation-result-with-data';

@Injectable({ providedIn: 'root' })
export class UserGroupsService {
  constructor(
    private readonly _userGroupsGQL: UserGroupsGQL,
    private readonly _editUserGroupGQL: EditUserGroupGQL,
    private readonly _createUserGroupGQL: CreateUserGroupGQL,
    private readonly _userGroupInformationGQL: UserGroupInformationGQL,
  ) {}

  getUserGroups() {
    return this._userGroupsGQL.watch({ where: undefined }, { useInitialLoading: true }).valueChanges;
  }

  create(input: UserGroupCreateInput) {
    return this._createUserGroupGQL
      .mutate(
        { input },
        {
          refetchQueries: [{ query: UserGroupsDocument, variables: { where: undefined } }, 'UserGroups'],
        },
      )
      .pipe(
        filter(isMutationWithData),
        map((resp) => resp.data.userGroupCreate),
      );
  }

  edit(userGroupEditInput: UserGroupEditInput) {
    return this._editUserGroupGQL.mutate({ input: userGroupEditInput }).pipe(
      filter(isMutationWithData),
      map((resp) => resp.data.userGroupEdit),
    );
  }

  getUserGroupsForUser(id: string) {
    return this._userGroupInformationGQL
      .fetch({ where: { id: { eq: id } } })
      .pipe(map((res) => res.data.currentUser.homeAccount.account.groups[0]));
  }
}
