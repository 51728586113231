import { EMPTY, Observable, catchError, map, throwError } from 'rxjs';

export const INVALID_UUID = 'INVALID_UUID';

const isUuid = (value: string): boolean => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(value);
};

interface InvalidUuidParamHandlerOptions {
  /**
   * If true, the error will be thrown for additional processing in catchError methods.
   *
   * Otherwise it will be silenced when the shouldRedirectToNotFoundPage returns true,
   *
   * @default false
   */
  readonly continueOnInvalidUuid: boolean;
}

export const invalidUuidParamHandler =
  (
    callback: () => void,
    { continueOnInvalidUuid: continueOnErrorCatch }: InvalidUuidParamHandlerOptions = { continueOnInvalidUuid: false },
  ) =>
  (id$: Observable<string>): Observable<string> =>
    id$.pipe(
      map((id) => {
        if (!isUuid(id)) {
          throw new Error(INVALID_UUID);
        }

        return id;
      }),

      catchError((err) => {
        // pass through other errors for additional processing in catchError methods
        if (err.message !== INVALID_UUID) {
          return throwError(() => err);
        }

        callback();
        return continueOnErrorCatch ? throwError(() => err) : EMPTY;
      }),
    );
