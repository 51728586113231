import { ChangeDetectorRef, Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { FeatureFlagProps, FlagAdditionalContext } from '../models/feature-flag';
import { FeatureFlagService } from '../services/feature-flag/feature-flag.service';
import { isFlagEnabled } from '../utils/feature-flag.utils';

@Directive({
  selector: '[hxpKernelFeatureFlag]',
  standalone: true,
})
export class FeatureFlagDirective implements OnChanges {
  @Input({ required: true }) hxpKernelFeatureFlag!: {
    readonly flag: FeatureFlagProps;
    readonly context?: FlagAdditionalContext;
  };

  constructor(
    private readonly _ff: FeatureFlagService,
    private readonly _view: ViewContainerRef,
    private readonly _template: TemplateRef<any>,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (!this.hxpKernelFeatureFlag.flag) {
      throw new Error('Feature flag directive requires a flag name or a variant');
    }

    const flagName = this.hxpKernelFeatureFlag.flag;

    this._ff
      .getFlag(flagName, this.hxpKernelFeatureFlag.context)
      .pipe(
        map((response) => isFlagEnabled(response)),
        distinctUntilChanged(),
        tap((isEnabled) => {
          if (isEnabled) {
            this._view.createEmbeddedView(this._template);
          } else {
            this._view.clear();
          }

          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
