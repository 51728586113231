import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationOverviewGQL, ApplicationOverviewQuery } from '@hxp/graphql';
import { routeParameter } from '@hxp/kernel';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './application-overview.view.html',
  styleUrls: ['./application-overview.view.scss'],
})
export class ApplicationOverviewView {
  overview$: Observable<ApplicationOverviewQuery>;

  constructor(route: ActivatedRoute, overviewQuery: ApplicationOverviewGQL) {
    this.overview$ = route.parent!.params.pipe(
      routeParameter<string>('appId'),
      switchMap((id) => overviewQuery.watch({ id }).valueChanges),
      filter((result) => !!result.data),
      map((result) => result.data),
    );
  }
}
