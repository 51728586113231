<ng-container *transloco="let t; read: 'users.users-components.user-operations.user-operations'">
  <mat-card>
    <mat-card-header class="pt-0">
      <mat-card-title>{{ t('header') }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="grid grid-cols-2 2xl:grid-cols-3 gap-6 w-full pt-6">
        <mat-card appearance="outlined" class="dashboard-card">
          <mat-card-header class="dashboard-card__section">
            <mat-icon mat-card-avatar hyIcon>user</mat-icon>
            <mat-card-title class="card-header flex-1">{{ t('users-card-title') }}</mat-card-title>
          </mat-card-header>
          <mat-divider class="-mx-6"></mat-divider>
          <mat-card-actions class="dashboard-card__section">
            <button class="flex-1" mat-button color="primary" (click)="handleAddInternalUserWizard()" data-testid="home-page-new-user">
              {{ t('new-user-button-text') }}
            </button>
            <button class="flex-1" mat-button color="primary" [routerLink]="['/identity/users']" data-testid="home-page-find-user">
              {{ t('find-user-button-text') }}
            </button>
          </mat-card-actions>
        </mat-card>

        <mat-card appearance="outlined" class="dashboard-card">
          <mat-card-header class="dashboard-card__section">
            <mat-icon mat-card-avatar hyIcon>user_group</mat-icon>
            <mat-card-title class="card-header flex-1">{{ t('user-groups-card-title') }}</mat-card-title>
          </mat-card-header>
          <mat-divider class="-mx-6"></mat-divider>
          <mat-card-actions class="dashboard-card__section">
            <button class="flex-1" mat-button color="primary" [routerLink]="['/identity/user-groups/add-user-group']">
              {{ t('new-user-group-button-text') }}
            </button>
            <button class="flex-1" mat-button color="primary" [routerLink]="['/identity/user-groups']">
              {{ t('find-user-group-button-text') }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
