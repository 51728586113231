import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppUserRightsGQL,
  AppUserRightsQuery,
  PayloadOfBoolean,
  RoleAssignUserGroupsForAccountInput,
  RoleAssignUserGroupsForEnvironmentGQL,
  RoleAssignUserGroupsForEnvironmentInput,
} from '@hxp/graphql';
import { routeParameter } from '@hxp/kernel';
import { TRANSLATION_SCOPE, TranslatedToastService } from '@hxp/shared/i18n';
import { Observable, Subject } from 'rxjs';
import { filter, map, single, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './app-user-rights.view.html',
  styleUrls: ['./app-user-rights.view.scss'],
  providers: [
    TranslatedToastService,
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'nucleus.applications.components.application-user-rights',
    },
  ],
})
export class AppUserRightsView implements OnDestroy {
  userRights$?: Observable<AppUserRightsQuery | undefined>;
  appKey?: string;

  private _appEnvironmentId?: string;
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    readonly _route: ActivatedRoute,
    readonly _appUserRightsGQL: AppUserRightsGQL,
    private readonly _updateUserRightsGQL: RoleAssignUserGroupsForEnvironmentGQL,
    private _translatedToastService: TranslatedToastService,
  ) {
    this.userRights$ = _route.parent!.params.pipe(
      routeParameter<string>('appId'),
      switchMap((id) => _appUserRightsGQL.watch({ id }).valueChanges),
      filter((result) => !!result.data),
      map((result) => {
        const subscribedApp = result.data.currentUser.homeAccount.account.subscribedApp;

        if (subscribedApp) {
          this._appEnvironmentId = subscribedApp.environment?.id;
          this.appKey = subscribedApp.appKey;

          return result.data;
        }

        return undefined;
      }),
    );
  }

  ngOnDestroy() {
    this._unsubscribe$.unsubscribe();
  }

  editUserRights(editedInput: RoleAssignUserGroupsForAccountInput) {
    if (!(editedInput.addUserGroupIds || editedInput.removeUserGroupIds || !this._appEnvironmentId) || !this.appKey) {
      return;
    }
    const editEnvironmentInput: RoleAssignUserGroupsForEnvironmentInput = {
      appKey: this.appKey,
      roleKey: editedInput.roleKey ? editedInput.roleKey : '',
      environmentId: this._appEnvironmentId ? this._appEnvironmentId : '',
      addUserGroupIds: editedInput.addUserGroupIds,
      removeUserGroupIds: editedInput.removeUserGroupIds,
    };
    this._updateUserRightsGQL
      .mutate(
        { input: editEnvironmentInput },
        {
          refetchQueries: ['AppUserRights'],
        },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((resp: any) => {
          return resp.data.roleAssignUserGroupsForEnvironment as PayloadOfBoolean;
        }),
        single(),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(
        (isSuccessfulPayload: PayloadOfBoolean) => {
          if (!isSuccessfulPayload.value) {
            this._translatedToastService.error('data-edit-error-text');
          }
        },
        () => this._translatedToastService.error('data-edit-error-text'),
      );
  }
}
