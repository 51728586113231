<ng-container *transloco="let t; read: 'users.account-access'">
  <div class="mat-app-background h-full flex flex-row">
    <div class="card-background mat-elevation-z2 account-app__list">
      <h2 class="mx-4 my-6">{{ t('list-header') }}</h2>
      <div *ngIf="accountApps$ | async as apps" data-testid="account-access-app-list">
        <mat-nav-list hyList>
          <hxp-kernel-keyboard-nav-list [list]="apps" [selectedRouteID]="selectedAppId" #navlist [loading]="loading()">
            <hxp-kernel-keyboard-nav-list-item
              *ngFor="let app of apps let i = index"
              [routerLink]="app.id"
              [displayName]="app.name"
              [isActiveFocusIndex]="i === navlist.keyboardEventsManager?.activeItemIndex"
            ></hxp-kernel-keyboard-nav-list-item>
          </hxp-kernel-keyboard-nav-list>
        </mat-nav-list>
      </div>
    </div>

    <div class="account-app__content border-solid border-y-0 border-r-0 border-l border-foreground-divider bg-white">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
