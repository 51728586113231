<div class="dashboard">
  <!-- banner -->
  <hxp-dashboard-banner class="dashboard__banner"></hxp-dashboard-banner>

  <div class="dashboard__container">
    <div class="dashboard__users-management mb-10">
      <app-admin-users-operations></app-admin-users-operations>
    </div>
    <hxp-admin-subscription-cards data-testid="admin-subs-cards"></hxp-admin-subscription-cards>
  </div>
</div>
