<div class="wizard" *transloco="let t">
  <hy-dialog
    class="flex flex-col overflow-y-auto"
    [header]="header"
    (confirmed)="next()"
    [confirmLabel]="nextLabel()"
    (customActionSelected)="previous()"
    [customLabel]="previousLabel()"
    (dismissed)="cancel()"
    [dismissLabel]="cancelLabel()"
    #dialog
  >
    <button
      *ngIf="dialog._dialogRef.disableClose"
      class="absolute top-4 right-4"
      mat-icon-button
      (click)="cancel()"
      tabindex="-1"
      type="button"
      [title]="'hy.hy-dialog.close-dialog' | transloco"
      [attr.aria-label]="'hy.hy-dialog.close-dialog' | transloco"
    >
      <mat-icon hyIcon>x_large</mat-icon>
    </button>

    <div class="content h-full pt-6 box-border">
      <ng-content></ng-content>
    </div>
  </hy-dialog>
</div>
