import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClientError, Error as HxPGraphQLError, Maybe } from '@hxp/graphql';
import { FormFieldGhostComponent, FormValidators, KernelComponentsModule, fieldMaxLength, fieldPattern } from '@hxp/kernel';
import { TRANSLATION_SCOPE, TranslatedToastService } from '@hxp/shared/i18n';
import { HyMaterialButtonModule, HyMaterialFormFieldModule, HyTranslateService } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { EmailValidatorService } from '../../../validators/email-validator/email-validator.service';
import { UsernameValidatorService } from '../../../validators/username-validator/username-validator.service';
import { UserInformation } from '../../user-information';

/* cspell:words firstname lastname Alreadyexists */
export interface UserInformationFormValues {
  readonly firstname?: string;
  readonly lastname?: string;
  readonly username: string;
  readonly initialUserName: string;
  readonly email: string;
  readonly initialEmail: string;
}

/**
 * the fields names associated with ClientError responses from backend when we create/edit user
 */

enum BackendUserFields {
  Emails = 'Emails',
  UserName = 'UserName',
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TranslatedToastService,
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'users.users-components.user-form',
    },
  ],
  standalone: true,
  imports: [
    TranslocoModule,
    KernelComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldGhostComponent,
    MatFormFieldModule,
    HyMaterialFormFieldModule,
    HyMaterialButtonModule,
    MatInputModule,
    MatProgressBarModule,
    NgClass,
    NgIf,
  ],
})
export class UserFormComponent implements OnInit, OnDestroy {
  // we need to use set/get here to manually update form values, when input change

  @Input()
  get userInformation(): UserInformation | undefined | null {
    return this._userInformation;
  }
  set userInformation(userInformation: UserInformation | undefined | null) {
    if (!userInformation) {
      return;
    }

    this._userInformation = userInformation;
    this.updateForm(userInformation);
  }
  private _userInformation: UserInformation | undefined | null;

  @Input()
  get submitting(): boolean {
    return this._submitting;
  }
  set submitting(isSubmitting: boolean) {
    this._submitting = coerceBooleanProperty(isSubmitting);

    if (isSubmitting) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  private _submitting!: boolean;

  @Input()
  isLoading!: boolean;

  @Input()
  confirmEmail = false;

  @Output()
  formChange = new EventEmitter<boolean>();

  @Output()
  formSubmit = new EventEmitter<UserInformationFormValues>();

  @Output()
  formValueChanged = new EventEmitter<UserInformationFormValues>();

  private readonly _unsubscribe$ = new Subject<void>();

  readonly form = this._formBuilder.group({
    firstname: ['', [Validators.maxLength(fieldMaxLength.FirstName)]],
    lastname: ['', [Validators.maxLength(fieldMaxLength.LastName)]],
    username: [
      '',
      {
        validators: [Validators.required, Validators.pattern(fieldPattern.UserName), Validators.maxLength(fieldMaxLength.UserName)],
      },
    ],
    initialUserName: ['', { disabled: true }],
    email: [
      '',
      {
        validators: [Validators.required, Validators.pattern(fieldPattern.Email), Validators.maxLength(fieldMaxLength.Email)],
      },
    ],
    initialEmail: ['', { disabled: true }],
  });

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _translate: HyTranslateService,
    @Inject(TRANSLATION_SCOPE) private readonly _translationScope: string,
    private readonly _userNameValidationService: UsernameValidatorService,
    private readonly _emailValidationService: EmailValidatorService,
    private readonly _cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.confirmEmail) {
      this._addConfirmEmailControl();
      this._handleEmailsMatchValidity();
    }
    this._handlePendingIssueForProgressBar();
    this.listenForUserChanges();
    this.form.valueChanges.pipe(takeUntil(this._unsubscribe$), distinctUntilChanged()).subscribe((val) => this.formValueChanged.emit(val));
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onBlur(control: AbstractControl, type: 'email' | 'username') {
    switch (type) {
      case 'email':
        this._emailValidationService.validate('initialEmail');
        control.addAsyncValidators(this._emailValidationService.validate('initialEmail'));
        break;
      case 'username':
        this._userNameValidationService.validate('initialUserName');
        control.addAsyncValidators(this._userNameValidationService.validate('initialUserName'));
        break;
    }

    control.updateValueAndValidity();
    control.clearAsyncValidators();
  }

  submitForm() {
    this.formSubmit.emit(this.form.value);
  }

  /**
   * Listen for user changes made to the form through the UI, and notify the parent component,
   * if current form values are different or not, then the one stored in database
   */
  listenForUserChanges(): void {
    this.form.valueChanges
      .pipe(takeUntil(this._unsubscribe$), debounceTime(300), this.trackUserEdit(), distinctUntilChanged())
      .subscribe((val) => this.formChange.emit(val));
  }

  updateForm(user: UserInformation): void {
    this.form.patchValue({
      firstname: user.name.givenName,
      lastname: user.name.familyName,
      username: user.userName,
      initialUserName: user.userName,
      email: user.emails[0]?.value,
      initialEmail: user.emails[0]?.value,
    });

    this._updateAsyncValidators();
  }

  /**
   * Check if the payload ClientError contained a know errors that could be displayed for an user in the UI as mat-error inside form field
   */

  handleBackendPayloadError(errors: Array<Maybe<ClientError> | undefined>) {
    const alreadyExistsFieldError = this._getAlreadyExistsField(errors);
    if (alreadyExistsFieldError) {
      this._setAlreadyExistTypeErrorInForm(alreadyExistsFieldError);
    }
  }

  getErrorMessage(control: AbstractControl): string {
    if (!control) {
      return '';
    }
    if (control.hasError('required')) {
      return this._translate.get(`${this._translationScope}.required-field-error-message`);
    }
    if (control.hasError('maxlength')) {
      const maxLen = control.errors?.maxlength.requiredLength;
      return this._translate.get(`${this._translationScope}.maxlength-field-error-message`, { maxLength: maxLen });
    }
    if (control.hasError('fieldsMatch')) {
      return this._translate.get(`${this._translationScope}.confirm-email-field-error-message`);
    }
    if (control.hasError('usernameAlreadyExists')) {
      return this._translate.get(`${this._translationScope}.user-exist-field-error-message`);
    }
    if (control.hasError('emailAlreadyExists')) {
      return this._translate.get(`${this._translationScope}.email-exist-field-error-message`);
    }
    if (control.hasError('pattern')) {
      if (control === control.parent?.get('email') || control === control.parent?.get('confirmEmail')) {
        return this._translate.get(`${this._translationScope}.email-pattern-error-message`);
      } else if (control === control.parent?.get('username')) {
        return this._translate.get(`${this._translationScope}.username-pattern-error-message`);
      }
    }
    return '';
  }

  trackUserEdit() {
    return (formEditedValues$: Observable<UserInformationFormValues>): Observable<boolean> =>
      formEditedValues$.pipe(
        map(
          (formValue) =>
            this.userInformation?.name.givenName !== formValue.firstname ||
            this.userInformation?.name.familyName !== formValue.lastname ||
            this.userInformation?.userName !== formValue.username ||
            this.userInformation?.emails[0]?.value !== formValue.email,
        ),
      );
  }

  private _getAlreadyExistsField(errors: Array<Maybe<ClientError> | undefined>): Maybe<string> | undefined {
    return errors.find((err) => err?.code === HxPGraphQLError.Alreadyexists)?.field;
  }

  private _setAlreadyExistTypeErrorInForm(field: string): void {
    switch (field) {
      case BackendUserFields.Emails:
        this._setEmailAlreadyExistError();
        break;

      case BackendUserFields.UserName:
        this._setUsernameAlreadyExistError();
        break;

      default:
        break;
    }
  }

  private _setEmailAlreadyExistError(): void {
    const control = this.form.get('email');

    if (control) {
      this._emailValidationService.addToAlreadyExistList(control.value);
      control.updateValueAndValidity();
    }
  }

  private _setUsernameAlreadyExistError(): void {
    const control = this.form.get('username');

    if (control) {
      this._userNameValidationService.addToAlreadyExistList(control.value);
      control.updateValueAndValidity();
    }
  }

  /*
   * Manually call update on async validators, since they updates on blur, to have correct initial state when we fill the form,
   * and prevent form error that this email/username already exists, for the current viewed user
   */

  private _updateAsyncValidators(): void {
    this.form.get('username')?.updateValueAndValidity();
    this.form.get('email')?.updateValueAndValidity();
  }

  private _handleEmailsMatchValidity() {
    this.form
      .get('email')
      ?.valueChanges.pipe(takeUntil(this._unsubscribe$), distinctUntilChanged())
      .subscribe(() => {
        this.form.get('confirmEmail')?.updateValueAndValidity();
      });
  }

  private _addConfirmEmailControl() {
    this.form.addControl(
      'confirmEmail',
      this._formBuilder.control('', [
        Validators.required,
        Validators.pattern(fieldPattern.Email),
        Validators.maxLength(fieldMaxLength.Email),
        FormValidators.fieldsMatch('email'),
      ]),
    );
  }

  private _handlePendingIssueForProgressBar() {
    // https://github.com/angular/angular/issues/12378
    // the form control pending status not updates correctly when used inside components with onPush strategy,
    // so we need to markForCheck the component manually to hide the progress bar when the pending status change
    // otherwise it would only correctly update when user blur out or trigger other event to re-run change detection

    this.form.statusChanges
      .pipe(
        tap((status) => {
          if (status !== 'PENDING') {
            this._cd.markForCheck();
          }
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }
}
