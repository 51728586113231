import { Injectable, inject } from '@angular/core';
import * as R from 'ramda';

type PageSettings = Record<string, number>;

@Injectable({
  providedIn: 'root',
})
export class PaginationSettingsService {
  static readonly STORAGE_KEY_PAGE_SIZE_SETTING = 'page-size-setting';
  private readonly _localStorage = inject(Storage);

  savePageSize(pageSize: number, key: string): void {
    const settings = this._getSettings();
    const updatedSettings = R.assoc(key, pageSize, settings);

    this._localStorage.setItem(PaginationSettingsService.STORAGE_KEY_PAGE_SIZE_SETTING, JSON.stringify(updatedSettings));
  }

  getPageSize(key: string, allowedValues: readonly number[], defaultValue: number): number {
    const pageSize = this._getPageSizeFromStorage(key);

    if (!pageSize) {
      return defaultValue;
    }

    const closestAllowedValue = allowedValues.reduce((prev, curr) => {
      return Math.abs(curr - pageSize) < Math.abs(prev - pageSize) ? curr : prev;
    }, 0);

    return allowedValues.includes(pageSize) ? pageSize : closestAllowedValue;
  }

  private _getPageSizeFromStorage(key: string): number {
    const settings = this._getSettings();

    return settings[key];
  }

  private _getSettings(): PageSettings {
    const settingsJson = this._localStorage.getItem(PaginationSettingsService.STORAGE_KEY_PAGE_SIZE_SETTING);

    if (settingsJson) {
      return JSON.parse(settingsJson);
    }

    return {};
  }
}
