import { HyAuthConfig } from '@hyland/ui';
import { AuthConfig } from 'angular-oauth2-oidc';
import { autoImplement } from '../types';

/* cspell:words userinfo */

type HyAuthConfigOptions = Pick<HyAuthConfig, 'apiEndpoints' | 'silentRefresh' | 'acrValues'>;
type AuthConfigOptions = Pick<
  AuthConfig,
  | 'issuer'
  | 'scope'
  | 'clientId'
  | 'redirectUri'
  | 'postLogoutRedirectUri'
  | 'silentRefreshRedirectUri'
  | 'requireHttps'
  | 'nonceStateSeparator'
  | 'sessionChecksEnabled'
  | 'useSilentRefresh'
>;
export interface OidcConfigurationOptions extends HyAuthConfigOptions, AuthConfigOptions {}

export class OidcConfiguration extends autoImplement<OidcConfigurationOptions>() {
  constructor(config?: Partial<OidcConfigurationOptions>) {
    super();

    this.scope = config?.scope || 'openid hxp profile';
    this.clientId = config?.clientId || 'hxp:clients:nucleus';
    this.silentRefresh = config?.silentRefresh ?? true;
    this.useSilentRefresh = config?.useSilentRefresh ?? true;
    this.requireHttps = config?.requireHttps || false;
    this.sessionChecksEnabled = config?.sessionChecksEnabled ?? true;
    // Default separator is ; but AWS gateway will drop everything after semicolons in URLs
    this.nonceStateSeparator = config?.nonceStateSeparator || '_';
    this.acrValues = config?.acrValues || [];
    this.apiEndpoints = config?.apiEndpoints || [];

    this.issuer = config?.issuer;
    this.postLogoutRedirectUri = config?.postLogoutRedirectUri;
    this.redirectUri = config?.redirectUri;
    this.silentRefreshRedirectUri = config?.silentRefreshRedirectUri;
  }

  get userInfoEndpoint() {
    return this.issuer + '/connect/userinfo';
  }

  get logoutUrl() {
    return this.issuer + '/connect/endsession';
  }
}
