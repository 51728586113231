import { NgModule } from '@angular/core';

import { HyLocalStorageService } from '@hyland/ui';

import { EnvironmentContextService } from './environment-context.service';

@NgModule({
  providers: [
    HyLocalStorageService,
    EnvironmentContextService,
  ],
})
export class EnvironmentContextModule { }
