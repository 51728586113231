import { FeatureFlagResponse } from '../models/feature-flag';
import { isSuccessResponse } from './feature-flag-type-guards.utils';

export const isFlagEnabled = (apiResponse: FeatureFlagResponse): boolean => {
  if (!isSuccessResponse(apiResponse)) {
    return false;
  }

  if (apiResponse.value) {
    return true;
  }

  return false;
};
