<ng-container *transloco="let t; read: 'admin-portal.dialogs.new-subscription-dialog'">
  <hy-dialog
    #dialog
    header="{{ t('header') }}"
    confirmLabel="{{ t('confirm-label') }}"
    dismissLabel="{{ t('dismiss-label') }}"
    (confirmed)="onConfirmed()"
    (dismissed)="onDismissed()"
  >
    <div class="flex flex-col dialog-content">
      <p class="mat-body">{{ t('dialog-text-p1') }}</p>
      <p class="mat-body">{{ t('dialog-text-p2') }}</p>
      <mat-checkbox color="primary"> {{ t('do-not-show-again') }} </mat-checkbox>
    </div>
  </hy-dialog>
</ng-container>
