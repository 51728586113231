import SHA256 from 'crypto-js/sha256';
import { pipe } from 'ramda';

export const anonymizeString = (message: string): string => SHA256(message).toString();
export const sanitizeUrl = (url: string): string => pipe(removeQueryParams, replaceUuidValuesWithId)(url);

const removeQueryParams = (url: string): string => {
  const index = url.indexOf('?');

  if (index > -1) {
    return url.slice(0, index);
  }

  return url;
};

const replaceUuidValuesWithId = (url: string): string => {
  const guidMatch =
    // eslint-disable-next-line no-useless-escape
    /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g;

  return url.replace(guidMatch, ':id');
};
