import { APP_INITIALIZER } from '@angular/core';
import { ConfigurationService } from './configuration.service';

export function configInitializer(configService: ConfigurationService) {
  return () => configService.loadAppConfig();
}

export const CONFIG_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: configInitializer,
  multi: true,
  deps: [ConfigurationService],
};
