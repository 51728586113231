import { Inject, Injectable, Injector } from '@angular/core';
import { HyAuthService, HyBrowserRedirectAuthService } from '@hyland/ui';
import { ConfigurationService } from '../configuration/configuration.service';
import { FakeAuthService } from './fake-auth.service';
import { USE_FAKE_AUTH_TOKEN } from './use-fake-auth.token';

@Injectable({ providedIn: 'root' })
export class AuthService extends HyAuthService {
  private readonly _auth: HyAuthService = this._getAuth();

  get authenticated() {
    return this._auth.authenticated;
  }

  authenticated$ = this._auth.authenticated$;
  idpUnreachable$ = this._auth.idpUnreachable$;

  constructor(
    private readonly _config: ConfigurationService,
    private readonly _injector: Injector,
    @Inject(USE_FAKE_AUTH_TOKEN) private readonly _useFakeAuth: boolean,
  ) {
    super();
  }

  getIdToken = this._auth.getIdToken.bind(this._auth);
  getAccessToken = this._auth.getAccessToken.bind(this._auth);
  getUserProfile = this._auth.getUserProfile.bind(this._auth);
  login = this._auth.login.bind(this._auth);
  loginCallback = this._auth.loginCallback.bind(this._auth);
  logout = this._auth.logout.bind(this._auth);

  private _getAuth(): HyAuthService {
    return this._useFakeAuth || this._config.getConfig().useFakeAuth
      ? this._injector.get(FakeAuthService)
      : this._injector.get(HyBrowserRedirectAuthService);
  }
}
