import { Injectable } from '@angular/core';
import {
  ResetUserPasswordMutationGQL,
  ResetUserPasswordMutationMutation,
  ServiceUserCreateInput,
  ServiceUserEditInput,
  ServiceUserFormAddUserGQL,
  User,
  UserAccountsUsersDocument,
  UserCreateInput,
  UserEditInput,
  UserFormAddUserGQL,
  UserFormEditServiceUserGQL,
  UserFormEditServiceUserMutation,
  UserFormEditUserGQL,
  UserFormResendVerificationEmailGQL,
  UserFormResendVerificationEmailMutation,
  UserInformationDocument,
  UserInformationGQL,
  UserInformationQuery,
  UserReactivateGQL,
  UserUnlockGQL,
} from '@hxp/graphql';
import { WithLoadingStatus } from '@hxp/kernel';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, single } from 'rxjs/operators';

export type UserEditResponsePayload = UserFormEditServiceUserMutation['serviceUserEdit'];
export type ResendVerificationEmailResponsePayload = UserFormResendVerificationEmailMutation['userSetPasswordAndConfirmEmail'];
export type ResetPasswordResponsePayload = ResetUserPasswordMutationMutation['userAdministratorResetPassword'];

const getUserForCacheSearch = (userId: string): Pick<User, '__typename' | 'id'> => {
  return {
    __typename: 'User',
    id: userId,
  };
};

@Injectable({
  providedIn: 'root',
})
export class UserInformationService {
  constructor(
    private readonly _apollo: Apollo,
    private readonly _resendVerificationEmailService: UserFormResendVerificationEmailGQL,
    private readonly _resetPasswordService: ResetUserPasswordMutationGQL,
    private readonly _addUserService: UserFormAddUserGQL,
    private readonly _addServiceUserService: ServiceUserFormAddUserGQL,
    private readonly _editUserService: UserFormEditUserGQL,
    private readonly _userUnlockGQL: UserUnlockGQL,
    private readonly _userReactivate: UserReactivateGQL,
    private readonly _editServiceUserService: UserFormEditServiceUserGQL,
    private readonly _userInfoService: UserInformationGQL,
  ) {}

  getUser(id: string) {
    return this._userInfoService.watch({ id }, { useInitialLoading: true }).valueChanges.pipe(
      map((queryResponse): WithLoadingStatus<UserInformationQuery['currentUser']['homeAccount']['account']['users'][number]> => {
        return {
          loading: queryResponse.loading,
          value: queryResponse.data ? queryResponse.data.currentUser.homeAccount.account.users[0] : undefined,
        };
      }),
    );
  }

  refetchUser() {
    return this._apollo.client.refetchQueries({
      include: [UserInformationDocument],
    });
  }

  editServiceUser(serviceUserEditInput: ServiceUserEditInput): Observable<UserEditResponsePayload | undefined> {
    return this._editServiceUserService
      .mutate(
        { input: serviceUserEditInput },
        {
          refetchQueries: ['UserInformation'],
        },
      )
      .pipe(
        map((resp) => resp.data?.serviceUserEdit),
        single(),
      );
  }

  editUser(userEditInput: UserEditInput): Observable<UserEditResponsePayload | undefined> {
    return this._editUserService
      .mutate(
        { input: userEditInput },
        {
          refetchQueries: ['UserInformation'],
        },
      )
      .pipe(
        map((resp) => resp.data?.userEdit),
        single(),
      );
  }

  unlockUser(userId: string) {
    return this._userUnlockGQL
      .mutate(
        {
          input: { userId },
        },
        {
          update: (cache, mutateResp) => {
            if (mutateResp.data?.userUnlock?.value) {
              cache.modify({
                id: cache.identify(getUserForCacheSearch(userId)),
                fields: {
                  locked() {
                    return false;
                  },
                },
              });
            }
          },
        },
      )
      .pipe(
        map((resp) => {
          return resp.data?.userUnlock;
        }),
        single(),
      );
  }

  reactivateUser(userId: string) {
    return this._userReactivate
      .mutate(
        {
          input: { userId },
        },
        {
          update: (cache, mutateResp) => {
            if (mutateResp.data?.userReactivate?.value) {
              cache.modify({
                id: cache.identify(getUserForCacheSearch(userId)),
                fields: {
                  deactivated() {
                    return false;
                  },
                },
              });
            }
          },
        },
      )
      .pipe(
        map((resp) => {
          return resp.data?.userReactivate;
        }),
        single(),
      );
  }

  resetPassword(userId: string) {
    return this._resetPasswordService.mutate({ input: { userId } }).pipe(
      map((resp) => resp.data?.userAdministratorResetPassword),
      single(),
    );
  }

  resendVerificationEmail(userId: string) {
    return this._resendVerificationEmailService.mutate({ input: { userId } }).pipe(
      map((resp) => resp.data?.userSetPasswordAndConfirmEmail),
      single(),
    );
  }

  createUserMutation(userCreateInput: UserCreateInput) {
    return this._addUserService
      .mutate(
        { input: userCreateInput },
        {
          refetchQueries: [{ query: UserAccountsUsersDocument, variables: { where: undefined } }, 'UserAccountsUsers'],
        },
      )
      .pipe(
        map((resp) => resp.data?.userCreate),
        single(),
      );
  }

  serviceUserCreateMutation(serviceUserCreateInput: ServiceUserCreateInput) {
    return this._addServiceUserService
      .mutate(
        { input: serviceUserCreateInput },
        {
          refetchQueries: [{ query: UserAccountsUsersDocument, variables: { where: undefined } }, 'UserAccountsUsers'],
        },
      )
      .pipe(
        map((resp) => resp.data?.serviceUserCreate),
        single(),
      );
  }
}
