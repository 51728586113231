import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { configureHyDialogOptions } from '@hyland/ui';
import { AddInternalUserDialog } from './add-internal-user.dialog';

@Injectable({
  providedIn: 'root',
})
export class AddInternalUserDialogService {
  constructor(private readonly _dialog: MatDialog) {}

  handleAddInternalUserWizard() {
    this._dialog.open(
      AddInternalUserDialog,
      configureHyDialogOptions({
        width: '800px',
        height: '650px',
        maxHeight: '80vh',
        maxWidth: '80vw',
        minWidth: '800px',
        disableClose: true,
      }),
    );
  }
}
