<div class="hxp-submitting-overlay__container">
  <div class="hxp-submitting-overlay__content">
    <ng-content></ng-content>
    <div class="hxp-submitting-overlay__progress-bar__overlay" *ngIf="submitting && showProgressBar">
      <mat-progress-bar mode="indeterminate" class="hxp-submitting-overlay__progress-bar"
        [ngClass]="{'hxp-submitting-overlay__progress-bar--above-buttons': positionProgressBarAboveButtons}">
      </mat-progress-bar>
    </div>
  </div>
  <div class="hxp-submitting-overlay__disabling-overlay" *ngIf="submitting">
  </div>
</div>
