import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AccountDetailsOverviewGQL } from '@hxp/graphql';
import { FormFieldGhostComponent, SharedLoadersModule } from '@hxp/kernel';
import { TRANSLATION_SCOPE } from '@hxp/shared/i18n';
import { HyMaterialModule, HyTranslateService } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { filter, map } from 'rxjs/operators';

/* cspell:words DEMOSYSTEM HYLANDSALESFORCE SEEDINGSYSTEM TESTSYSTEM */

interface SourceAccountSystemDescriptions {
  [key: string]: string;
  DEMOSYSTEM: string;
  HYLANDSALESFORCE: string;
  SEEDINGSYSTEM: string;
  TESTSYSTEM: string;
}

@Component({
  selector: 'hxp-account-details-overview',
  templateUrl: './account-details-overview.view.html',
  styleUrls: ['./account-details-overview.view.scss'],
  providers: [
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'nucleus.account-details.views.account-details-overview',
    },
  ],
  standalone: true,
  imports: [NgIf, AsyncPipe, FormFieldGhostComponent, TranslocoModule, FormsModule, MatInputModule, HyMaterialModule, SharedLoadersModule],
})
export class AccountDetailsOverviewView {
  private readonly _overviewQuery = inject(AccountDetailsOverviewGQL);
  private readonly _translate = inject(HyTranslateService);
  private readonly _translationScope = inject(TRANSLATION_SCOPE);

  readonly details$ = this._overviewQuery.watch().valueChanges.pipe(
    filter((result) => !!result.data),
    map((result) => result.data.currentUser.homeAccount.account),
  );

  // a dictionary of source account system labels
  sourceAccountSystemDescriptions: SourceAccountSystemDescriptions = {
    DEMOSYSTEM: this._translate.get(`${this._translationScope}.demo-system-label`),
    HYLANDSALESFORCE: this._translate.get(`${this._translationScope}.hyland-salesforce-label`),
    SEEDINGSYSTEM: this._translate.get(`${this._translationScope}.seeding-system-label`),
    TESTSYSTEM: this._translate.get(`${this._translationScope}.test-system-label`),
  };
}
