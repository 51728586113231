<mat-list hyList>
  <mat-list-item hyListItem class="bg-transparent">
    <hy-ghost-block class="ghost w-3/4" />
  </mat-list-item>
  <mat-list-item hyListItem class="bg-transparent">
    <hy-ghost-block class="ghost w-full" />
  </mat-list-item>
  <mat-list-item hyListItem class="bg-transparent">
    <hy-ghost-block class="ghost w-1/2" />
  </mat-list-item>
</mat-list>
