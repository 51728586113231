import { Component } from '@angular/core';
import { AddInternalUserDialogService } from '../../../../../components/add-internal-user-dialog/add-internal-user-dialog.service';

@Component({
  selector: 'app-admin-users-operations',
  templateUrl: './user-operations.component.html',
  styleUrls: ['./user-operations.component.scss'],
})
export class UserOperationsComponent {
  constructor(private readonly _addInternalUserDialogService: AddInternalUserDialogService) {}

  handleAddInternalUserWizard() {
    this._addInternalUserDialogService.handleAddInternalUserWizard();
  }
}
