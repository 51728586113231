import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface WithLoadingStatus<T> {
  readonly loading: boolean;
  readonly value?: T;
  readonly error?: Error;
}

export interface WithStartFinishStatus<T> {
  readonly status: 'start' | 'finish';
  readonly value: T;
}

const isStartFinishStatusObject = <T>(obj: T | WithStartFinishStatus<T>): obj is WithStartFinishStatus<T> => {
  return Object.prototype.hasOwnProperty.call(obj, 'status') && Object.prototype.hasOwnProperty.call(obj, 'value');
};

/**
 * Helper pipe to convert observable to one with loading status
 * the value fetched by the observable will be available under .value object
 *
 * * WARRING: to make it work with long lived observables, the logic component that creates the observable needs to concat
 * * an observable with { status: 'start' } (to immediately show the loading indicator) with the long lived observable that also has
 * * a { status: 'finish' } tacked on to the object when we finally get the data. The data itself needs to be passed under value prop.
 * * An example implementation can be found in the UserGroupsComponent
 *
 * @example
 * ```typescript
 * const obs$: Observable<WithStartFinishStatus<DisplayUserGroup[]>> = this.searchTerm$.pipe(
 *   debounceTime(300),
 *   switchMap((searchTerm: string) => {
 *     const searchFilter = searchTerm ? { name_contains: searchTerm } : undefined;
 *     return concat(
 *       of({ type: 'start', value: [] }),
 *       this.userGroupService.watch({ where: searchFilter }, { fetchPolicy: 'cache-and-network' }).valueChanges.pipe(
 *         filter((res) => !!res.data),
 *         map((res) => ({ value: res.data.userGroups, type: 'finish' })),
 *       ),
 *     );
 *   }),
 * );
 *  ```
 *
 * @usageNotes
 *
 * ```html
 * <div *ngIf="obs$ | appWithLoading | async as obs">
 *  <div *ngIf="obs.loading">...</div>
 *  <div *ngIf="obs.value">...</div>
 * </div>
 * ```
 */

@Pipe({
  name: 'appWithLoading',
  standalone: true,
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(val$: Observable<T>): Observable<WithLoadingStatus<T>> {
    return val$.pipe(
      map((value: T) => ({
        loading: isStartFinishStatusObject(value) ? value.status === 'start' : false,
        value,
      })),
      startWith({ loading: true }),
      catchError((error: Error) => {
        return of({ loading: false, error });
      }),
    );
  }
}
