import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UserNamesGQL } from '@hxp/graphql';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsernameValidatorService {
  /**
   * Helper set for handling edge cases when the async call _checkOnBackend would not detect the username is already taken
   * but we would get a response from the backend when creating/editing user:
   * ```
   * "userErrors": [
   *    { message: "User with username [USERNAME] already exists.", code: "ALREADYEXISTS", field: "Emails" }
   * ]
   * ```
   * and race condition when the user would be created in the same time, to correctly highlight error message in form, as the
   * _checkOnBackend call is currently catching values, and using no-cache policy would impact performance.
   */

  private _alreadyExistList: Set<string> = new Set();

  constructor(private readonly _userNames: UserNamesGQL) {}

  validate(currentValueControlName: string): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const currentUserName = control?.parent?.get(currentValueControlName)?.value;

      // if the username value is same as the one stored in database, we need to not show error message
      // to prevent issue on edit user page, and marking entire form as invalid for a user we want to edit

      if (currentUserName === control.value) {
        return of(null);
      }

      return this._checkExistence(control.value).pipe(
        map((usernameAlreadyExists) => (usernameAlreadyExists ? { usernameAlreadyExists: true } : null)),
      );
    };
  }

  addToAlreadyExistList(userName: string) {
    this._alreadyExistList.add(userName.toLowerCase());
  }

  private _checkExistence(userName: string): Observable<boolean> {
    const alreadyExist = this._alreadyExistList.has(userName.toLowerCase());

    if (alreadyExist) {
      return of(true);
    }

    return this._checkOnBackend(userName);
  }

  /*
   * Temporary work around till we get dedicated endpoint (JIRA:HXW-716) to validate username
   * as currently username on the backend is validated in case insensitive manner.
   * and the fetch call is not, so we need to fetch all values and compare it ignoring case sensitivity
   */

  private _checkOnBackend(userName: string): Observable<boolean> {
    return this._userNames.fetch({ where: { userName: { eq: userName } } }).pipe(
      filter((res) => !!res.data),
      map((res) => {
        return res.data.currentUser.homeAccount.account.users.length > 0;
      }),
    );
  }
}
