import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DisableFormControlDirective } from './disable-form-control.directive';
import { FormFieldPulseDirective } from './form-field-pulse.directive';
import { InputAutofocusDirective } from './input-autofocus.directive';

@NgModule({
  declarations: [DisableFormControlDirective, FormFieldPulseDirective, InputAutofocusDirective],
  exports: [DisableFormControlDirective, FormFieldPulseDirective, InputAutofocusDirective],
  imports: [FormsModule, ReactiveFormsModule],
})
export class KernelDirectivesModule {}
