<ng-container *ngIf="accountApps$ | async as accountApps">
  <hy-hx-shell homeRoute="/" [helpLink]="helpLink" [linkItems]="accountApps" toolbarTitle="Administration Portal">

    <hy-shell-toolbar>
      <ng-container *hyShellToolbarContent>
        <div class="flex justify-between flex-row items-center w-full">
          <hxp-kernel-feature-flags class="mr-4" *hxpKernelFeatureFlag="{ flag: 'nucleus-ce-show-override-flag-options' }"/>

        </div>
      </ng-container>
    </hy-shell-toolbar>

    <ng-container *transloco="let t; read: 'admin-portal.nav.items'">
      <nav
        *ngIf="authService.authenticated"
        mat-tab-nav-bar
        hyTabGroup
        [tabPanel]="tabPanel"
        mat-stretch-tabs="false"
        class="bg-white"
        data-testid="main-nav"
      >
        <a
          data-testid="nav-tab-dashboard"
          mat-tab-link
          #dashboard="routerLinkActive"
          [active]="dashboard.isActive"
          routerLink=""
          routerLinkActive
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ t('dashboard') }}
        </a>
        <a
          data-testid="nav-tab-account"
          mat-tab-link
          #account="routerLinkActive"
          [active]="account.isActive"
          routerLink="/account"
          routerLinkActive
        >
          {{ t('account') }}
        </a>
        <a
          data-testid="nav-tab-identity"
          mat-tab-link
          #identity="routerLinkActive"
          [active]="identity.isActive"
          routerLink="/identity"
          routerLinkActive
        >
          {{ t('identity') }}
        </a>
      </nav>
    </ng-container>

    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  </hy-hx-shell>
</ng-container>
