import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkStatus } from '@apollo/client/core';
import { PayloadOfBoolean, RoleAssignUserGroupsForAccountGQL, RoleAssignUserGroupsForAccountInput, RoleUserGroupsGQL } from '@hxp/graphql';
import { routeParameter } from '@hxp/kernel';
import { TRANSLATION_SCOPE, TranslatedToastService } from '@hxp/shared/i18n';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, map, share, single, switchMap, takeUntil, tap } from 'rxjs/operators';
import { invalidUuidParamHandler } from '../../utils/invalid-uuid-param-handler';
import { notFoundValueHandler } from '../../utils/not-found-value-handler';
import { RoleMappings } from '../role-mappings';

@Component({
  templateUrl: './user-rights.view.html',
  styleUrls: ['./user-rights.view.scss'],
  providers: [
    TranslatedToastService,
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'users.account-access.user-rights',
    },
  ],
})
export class UserRightsView implements OnDestroy {
  accountAppName?: string;
  appKey?: string;
  isLoading = false;

  private readonly _roleMappings$ = new BehaviorSubject<readonly RoleMappings[]>([]);
  readonly roleMappings$ = this._roleMappings$.asObservable().pipe(
    share({
      connector: () => new ReplaySubject(1),
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false,
    }),
  );
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _roleUserGroupsGQL: RoleUserGroupsGQL,
    private readonly _roleAssignUserGroupsForAccount: RoleAssignUserGroupsForAccountGQL,
    private readonly _translatedToastService: TranslatedToastService,
  ) {
    this._route.params
      .pipe(
        routeParameter<string>('id'),
        invalidUuidParamHandler(this._redirectToNotFoundPage.bind(this)),
        switchMap((id) => this._getRoleMapping(id)),
        notFoundValueHandler(this._redirectToNotFoundPage.bind(this)),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((results) => {
        if (results) {
          this._roleMappings$.next(results);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  editUserRights(editedInput: RoleAssignUserGroupsForAccountInput) {
    if (!(editedInput.addUserGroupIds || editedInput.removeUserGroupIds) || !this.appKey) {
      return;
    }

    editedInput.appKey = this.appKey;

    this._roleAssignUserGroupsForAccount
      .mutate(
        { input: editedInput },
        {
          refetchQueries: ['RoleUserGroups'],
        },
      )
      .pipe(
        map((resp) => resp.data?.roleAssignUserGroupsForAccount as PayloadOfBoolean | undefined),
        single(),
        takeUntil(this._unsubscribe$),
      )
      .subscribe({
        next: (isSuccessfulPayload?: PayloadOfBoolean) => {
          if (!isSuccessfulPayload?.value) {
            this._translatedToastService.error('edit-role-mapping-error-text');
          }
        },
        error: () => this._translatedToastService.error('edit-role-mapping-error-text'),
      });
  }

  private _getRoleMapping(id: string) {
    return this._roleUserGroupsGQL.watch(undefined, { useInitialLoading: true }).valueChanges.pipe(
      tap((resp) => (this.isLoading = resp.loading && (!resp.data || resp.networkStatus === NetworkStatus.refetch))),
      filter((resp) => !!resp.data),
      map((resp) => {
        const app = resp.data.currentUser.homeAccount.account.accountApps.find((a) => a.id === id);

        if (!app) {
          return undefined;
        }

        this.appKey = app.appKey;
        this.accountAppName = app.app.localizedName;

        return app.roles ?? [];
      }),
    );
  }

  private _redirectToNotFoundPage(): void {
    void this._router.navigate(['../not-found'], { relativeTo: this._route, skipLocationChange: true });
  }
}
