<ng-container *transloco="let t; read: 'nucleus.applications.components.application-details'">
  <div
    class="app-details p-6 h-full flex flex-col box-border"
    *ngIf="subscribedApplication$ | async as subscribedApplication"
    data-testid="app-details"
  >
    <div class="flex flex-row items-end justify-start">
      <h2 class="mb-6">{{ subscribedApplication.app.localizedName }}</h2>
    </div>
    <div class="p-0 flex-col flex flex-1 h-full">
      <div class="card__header border-foreground-divider">
        <nav mat-tab-nav-bar class="hy-tab-group w-full flex" [tabPanel]="tabPanel" mat-stretch-tabs="false">
          <a
            mat-tab-link
            [routerLink]="[
              '/account',
              'environment',
              subscribedApplication.environment?.id,
              'apps',
              subscribedApplication.id,
              'overview'
            ]"
            routerLinkActive
            #overview="routerLinkActive"
            [active]="overview.isActive"
            [routerLinkActiveOptions]="{ exact: true }"
            >{{ t('tabs.application-overview') }}</a
          >

          <a
            mat-tab-link
            [routerLink]="[
              '/account',
              'environment',
              subscribedApplication.environment?.id,
              'apps',
              subscribedApplication.id,
              'user-rights'
            ]"
            routerLinkActive
            #usage="routerLinkActive"
            [active]="usage.isActive"
            [routerLinkActiveOptions]="{ exact: true }"
            >{{ t('tabs.application-user-rights') }}</a
          >
        </nav>
      </div>
      <mat-tab-nav-panel #tabPanel class="tab-wrapper h-full overflow-y-auto">
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</ng-container>
