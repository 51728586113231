import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppType, ApplicationDetailsGQL, ApplicationDetailsQuery } from '@hxp/graphql';
import { routeParameter } from '@hxp/kernel';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { invalidUuidParamHandler } from '../../../utils/invalid-uuid-param-handler';
import { notFoundValueHandler } from '../../../utils/not-found-value-handler';

type SubscribedApp = ApplicationDetailsQuery['currentUser']['homeAccount']['account']['subscribedApp'];

@Component({
  templateUrl: './app-details.view.html',
  styleUrls: ['./app-details.view.scss'],
})
export class AppDetailsView {
  readonly subscribedApplication$: Observable<SubscribedApp | undefined> = this._route.params.pipe(
    routeParameter<string>('appId'),
    invalidUuidParamHandler(this._redirectToNotFoundPage.bind(this)),
    switchMap((id) => this._applicationDetailsQuery.watch({ id }).valueChanges),
    map((result) => {
      const subscribedApp = result.data.currentUser.homeAccount.account.subscribedApp;

      if (subscribedApp?.app.appType === AppType.Template) {
        return undefined;
      }

      return subscribedApp;
    }),
    notFoundValueHandler(this._redirectToNotFoundPage.bind(this)),
  );

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _applicationDetailsQuery: ApplicationDetailsGQL,
  ) {}

  private _redirectToNotFoundPage(): void {
    void this._router.navigate(['../not-found'], { relativeTo: this._route, skipLocationChange: true });
  }
}
