/* eslint-disable @typescript-eslint/no-empty-function */
import { Inject, Injectable } from '@angular/core';
import { ANALYTICS_CONFIG, ANALYTICS_SERVICE, Analytics, AnalyticsConfig } from './analytics';

const isArray = <T>(value: T | readonly T[]): value is readonly T[] => {
  return Array.isArray(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPromise = <T = any>(obj: any): obj is Promise<T> => {
  return !!obj && typeof obj.then === 'function';
};

@Injectable({ providedIn: 'root' })
export class AnalyticsFacade {
  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analytics: Analytics | readonly Analytics[],
    @Inject(ANALYTICS_CONFIG) private readonly analyticsConfig?: AnalyticsConfig,
  ) {}

  initialize() {
    if (!this.analyticsConfig?.enable) {
      return;
    }

    if (isArray(this._analytics)) {
      for (const analytic of this._analytics) {
        this._callAnalyticInitialize(analytic);
      }
    } else {
      this._callAnalyticInitialize(this._analytics);
    }
  }

  private _callAnalyticInitialize(analytic: Analytics) {
    const init = analytic.initialize();

    if (isPromise(init)) {
      // to handle floating promise,
      init.then(() => {}).catch(() => {});
    }
  }
}
