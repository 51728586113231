import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hxp-kernel-submitting-overlay',
  templateUrl: './submitting-overlay.component.html',
  styleUrls: ['./submitting-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmittingOverlayComponent {
  @Input()
  get submitting(): boolean {
    return this._submitting;
  }
  set submitting(value: boolean) {
    this._submitting = coerceBooleanProperty(value);
  }
  private _submitting = false;

  @Input()
  get showProgressBar(): boolean {
    return this._showProgressBar;
  }
  set showProgressBar(value: boolean) {
    this._showProgressBar = coerceBooleanProperty(value);
  }
  private _showProgressBar = false;

  @Input()
  get positionProgressBarAboveButtons(): boolean {
    return this._positionProgressBarAboveButtons;
  }
  set positionProgressBarAboveButtons(value: boolean) {
    this._positionProgressBarAboveButtons = coerceBooleanProperty(value);
  }
  private _positionProgressBarAboveButtons = false;
}
