<ng-container *transloco="let t; read: 'nucleus.applications'">
  <div class="flex flex-row h-full">
    <div class="apps-list apps__list border-solid border-foreground-divider border-y-0 border-l-0 border-r">
      <h2 class="mx-4 my-6">{{ t('list-header') }}</h2>

      <ng-container *ngIf="subscriptions$ | async as subs">
        <div *ngIf="!loading(); else ghost" class="overflow-y-auto">
          <mat-nav-list hyList *ngFor="let sub of subs" tabindex="-1" class="pb-4">
            <div class="mb-1 mt-1"><span matSubheader class="mat-subtitle-2">{{ sub.title }}</span></div>

            <hxp-kernel-keyboard-nav-list data-testid="apps-list" [list]="sub.apps" [selectedRouteID]="selectedAppId" #navlist>
              <hxp-kernel-keyboard-nav-list-item
                [attr.hy-tracking-name]="app.key"
                *ngFor="let app of sub.apps; let i = index"
                [routerLink]="app.id"
                [displayName]="app.name"
                [isActiveFocusIndex]="i === navlist.keyboardEventsManager?.activeItemIndex"
              ></hxp-kernel-keyboard-nav-list-item>
            </hxp-kernel-keyboard-nav-list>
          </mat-nav-list>
        </div>

        <ng-template #ghost>
          <hxp-kernel-app-mat-list-ghost-loader></hxp-kernel-app-mat-list-ghost-loader>
        </ng-template>
      </ng-container>
    </div>

    <div class="apps__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
