import { RoleMappingContentListItem } from './role-mappings';

export function filterDatasource(data: RoleMappingContentListItem, filterStr: string) {
  const normalizedFilter = filterStr.trim().toLocaleLowerCase();
  const roleNameMatch = data.name.toLocaleLowerCase().indexOf(normalizedFilter) !== -1;
  const userGroupMatch =
    data.userGroupsLabel
      .toLocaleLowerCase()
      .indexOf(normalizedFilter) !== -1;

  return roleNameMatch || userGroupMatch;
}
