import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { HyDialogModule, HyMaterialListModule, HyMaterialModule, HyTranslateModule } from '@hyland/ui';
import { ContentCannotBeViewedComponent } from './content-cannot-be-viewed/content-cannot-be-viewed.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { EnvironmentSelectorDialog } from './environment-selector-dialog/environment-selector.dialog';
import { SubmittingOverlayComponent } from './submitting-overlay/submitting-overlay.component';

@NgModule({
  declarations: [EnvironmentSelectorDialog, ContentCannotBeViewedComponent, SubmittingOverlayComponent],
  exports: [EmptyStateComponent, EnvironmentSelectorDialog, ContentCannotBeViewedComponent, SubmittingOverlayComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    HyMaterialModule,
    HyMaterialListModule,
    HyDialogModule,
    RouterModule,
    HyTranslateModule,
    EmptyStateComponent,
  ],
})
export class KernelComponentsModule {}
