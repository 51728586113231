<ng-container *transloco="let t; read: 'users.users-components.user-form'">
  <div class="group-list">
    <h2 class="mat-subtitle-1 my-0 mr-6 mb-4">
      {{ t('user-groups-subheader') }} <span *ngIf="optionalLabel">({{ t('optional-label') }})</span>
    </h2>

    <div class="grid grid-cols-2 gap-6 mb-4">
      <hy-search-input
        data-testid="search-unassigned-user-group"
        class="search-input w-full"
        placeholder="{{ t('search-available-user-groups-input-placeholder') }}"
        ariaLabel="{{ t('search-available-user-groups-input-aria-text') }}"
        (search)="searchTerm$.next($event)"
        (clear)="searchTerm$.next('')"
        (valueChange)="searchTerm$.next($event)"
      >
      </hy-search-input>
    </div>

    <app-add-user-to-groups
      [unassignedGroups]="unassignedGroups$ | async"
      [userGroups]="preselectedUsers"
      [isLoading]="isLoading"
      (assignedGroupsChanges)="onSelectedItemsChanged($event)"
    ></app-add-user-to-groups>
  </div>
</ng-container>
