import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HyDialog, HyDialogModule, HyMaterialIconModule, HyMaterialModule, HyTranslateModule } from '@hyland/ui';
import { DialogWithWizardStore } from './dialog-with-wizard.store';

@Component({
  selector: 'hxp-dialog-with-wizard',
  standalone: true,
  imports: [
    CommonModule,
    HyMaterialModule,
    ReactiveFormsModule,
    HyDialogModule,
    HyTranslateModule,
    MatIconModule,
    HyMaterialIconModule,
    MatButtonModule,
  ],
  templateUrl: './dialog-with-wizard.component.html',
  styleUrls: ['./dialog-with-wizard.component.scss'],
  providers: [DialogWithWizardStore],
})
export class DialogWithWizardComponent implements AfterViewInit {
  @Input({ required: true }) header!: string;
  @ViewChild(HyDialog) dialog!: HyDialog;

  readonly nextLabel = this._store.nextLabel$;
  readonly previousLabel = this._store.previousLabel$;
  readonly cancelLabel = this._store.cancelLabel$;

  constructor(private readonly _store: DialogWithWizardStore) {}

  ngAfterViewInit(): void {
    this._store.setCloseDialogAction(() => this.dialog.close());
  }

  cancel() {
    this._store.callCancel();
  }

  next() {
    this._store.callNext();
  }

  previous() {
    this._store.callPrevious();
  }
}
