import { NgModule } from '@angular/core';
import { CardContentComponent } from './card-content/card-content.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CardComponent } from './card.component';

@NgModule({
  declarations: [CardHeaderComponent, CardContentComponent],
  imports: [CardComponent, CardFooterComponent],
  exports: [CardComponent, CardHeaderComponent, CardFooterComponent, CardContentComponent],
})
export class CardModule {}
