import { Inject, Injectable, Optional } from '@angular/core';
import { HyToastRef, HyToastService, HyTranslateService } from '@hyland/ui';
import { TRANSLATION_SCOPE } from './translation-scope';

@Injectable()
export class TranslatedToastService {
  constructor(
    private _toastService: HyToastService,
    private _translate: HyTranslateService,
    @Optional() @Inject(TRANSLATION_SCOPE) private _translationScope: string | null,
  ) {}

  success(translationKey: string, translationParams?: any): HyToastRef {
    return this._toastService.success(
      this._translate.get(this._getFullTranslationPath(translationKey), translationParams),
    );
  }

  warning(translationKey: string, translationParams?: any): HyToastRef {
    return this._toastService.warning(
      this._translate.get(this._getFullTranslationPath(translationKey), translationParams),
    );
  }

  error(translationKey: string, translationParams?: any): HyToastRef {
    return this._toastService.error(
      this._translate.get(this._getFullTranslationPath(translationKey), translationParams),
    );
  }

  private _getFullTranslationPath(translationKey: string): string {
    return this._translationScope ? `${this._translationScope}.${translationKey}` : translationKey;
  }
}
