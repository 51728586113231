import { HY_SHELL_CONFIG_INITIALIZER, HyShellConfig } from '@hyland/ui-shell';
import { ConfigurationService } from './configuration.service';
import { guaranteeConfigurationExists } from './guaranteeConfigurationExists';

export function shellConfigInitializer(configService: ConfigurationService) {
  return guaranteeConfigurationExists(configService).then((config) => {
    const shellConfig: HyShellConfig = {
      appInfo: {
        name: 'Hyland Experience',
        termsConditionsUrl: config.termsConditionsUrl,
        privacyPolicyUrl: config.privacyPolicyUrl,
        usagePolicyUrl: config.usagePolicyUrl,
      },
      translateConfig: {
        availableLangs: [
          {
            label: 'English (US)',
            id: 'en-US',
          },
        ],
        defaultLang: 'en-US',
        fallbackLang: 'en-US',
        reRenderOnLangChange: true,
        prodMode: config.production,
      },
      authConfig: config.HY_AUTHORIZATION_CONFIG,
      disableThemeSetting: true,
      inactivityTimeout: false,
    };

    return shellConfig;
  });
}

export const SHELL_CONFIG_INITIALIZER = {
  provide: HY_SHELL_CONFIG_INITIALIZER,
  useFactory: shellConfigInitializer,
  deps: [ConfigurationService],
};
