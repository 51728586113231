<div class="flex flex-row gap-4 items-center" *transloco="let t; read: 'kernel.components.on-off-switch'">
  <hy-switch
    #switchRef
    (change)="valueChange.emit($event.checked)"
    [checked]="checked"
    [disabled]="disabled"
    [aria-label]="ariaLabel ?? undefined"
  >
    <span hy-switch-off-content>{{ t('off') }}</span>
    <span hy-switch-on-content>{{ t('on') }}</span>
  </hy-switch>
  <label [attr.id]="labelId" class="m-0" *ngIf="label" [attr.for]="buttonId">{{ label }}</label>
</div>
