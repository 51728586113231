import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TRANSLATION_SCOPE, TranslatedToastService } from '@hxp/shared/i18n';
import { HyTranslateService } from '@hyland/ui';

@Component({
  templateUrl: './account-details.view.html',
  styleUrls: ['./account-details.view.scss'],
  providers: [
    TranslatedToastService,
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'nucleus.account-details.views.account-details',
    },
  ],
})
export class AccountDetailsView implements OnInit {
  private readonly _title = inject(Title);
  private readonly _translate = inject(HyTranslateService);

  ngOnInit() {
    this._title.setTitle(this._translate.get('admin-portal.page-title.account-details'));
  }
}
