import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';

import { HyAuthModule, HyAuthService } from '@hyland/ui';

import introspectionResult from '@hxp/graphql';

import { ConfigurationService } from '../configuration/configuration.service';

/* cspell:words inmemory */

export function apolloOptionsFactory(httpLink: HttpLink, config: ConfigurationService) {
  const { graphqlApi } = config.getConfig();

  return {
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
    }),
    link: ApolloLink.from([httpLink.create({ uri: graphqlApi })]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  };
}

@NgModule({
  imports: [ApolloModule, HttpClientModule, HyAuthModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
      deps: [HttpLink, ConfigurationService, HyAuthService],
    },
  ],
})
export class GraphQLModule {}
