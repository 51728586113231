import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private _status$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  visible$: Observable<boolean> = this._status$.asObservable();

  hide() {
    this._status$.next(false);
  }

  show() {
    this._status$.next(true);
  }
}
