import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UserEmailAccountGQL } from '@hxp/graphql';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailValidatorService {
  /**
   * Helper set for handling edge cases when the async call _checkOnBackend would not detect the email is already taken
   * but we would get a response from the backend when creating/editing user:
   * ```
   * "userErrors": [
   *    { message: "A User with the email [EMAIL] already exists.", code: "ALREADYEXISTS", field: "Emails" }
   * ]
   * ```
   * and race condition when the user would be created in the same time, to correctly highlight error message in form, as the
   * _checkOnBackend call is currently catching values, and using no-cache policy would impact performance.
   */

  private _alreadyExistList: Set<string> = new Set();

  constructor(private readonly _userEmailAccount: UserEmailAccountGQL) {}

  validate(currentValueControlName: string): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const currentEmail = control?.parent?.get(currentValueControlName)?.value;

      // if the username value is same as the one stored in database, we need to not show error message
      // to prevent issue on edit user page, and marking entire form as invalid for a user we want to edit

      if (currentEmail === control.value) {
        return of(null);
      }

      return this._checkExistence(control.value).pipe(
        map((emailAlreadyExists) => (emailAlreadyExists ? { emailAlreadyExists: true } : null)),
      );
    };
  }

  addToAlreadyExistList(email: string) {
    this._alreadyExistList.add(email.toLowerCase());
  }

  private _checkExistence(email: string): Observable<boolean> {
    const alreadyChecked = this._alreadyExistList.has(email.toLowerCase());

    if (alreadyChecked) {
      return of(true);
    }

    return this._checkOnBackend(email);
  }

  /*
   * Temporary work around till we get dedicated endpoint (JIRA:HXW-716) to validate existence of email address
   * as currently email on the backend is validated in case insensitive manner.
   * and the fetch call is not, so we need to fetch all values and compare it ignoring case sensitivity
   */

  private _checkOnBackend(emailValue: string): Observable<boolean> {
    return this._userEmailAccount.fetch({ where: { email: { eq: emailValue } } }).pipe(
      filter((res) => !!res.data),
      map((res) => {
        return res.data.currentUser.homeAccount.account.users.length > 0;
      }),
    );
  }
}
