import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HyMaterialFormFieldModule } from '@hyland/ui';
import { ReusableFeatureFlagControl } from '../reusable-feature-flag-control';

@Component({
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, HyMaterialFormFieldModule],
  templateUrl: './feature-flag-json-form-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FeatureFlagJsonFormTypeComponent,
      multi: true,
    },
  ],
})
export class FeatureFlagJsonFormTypeComponent extends ReusableFeatureFlagControl {
  readonly control = new FormControl('{}');

  override writeValue(obj: Record<string, unknown> | string): void {
    const value = typeof obj === 'object' ? JSON.stringify(obj, undefined, 4) : obj;

    this.control.patchValue(value, { emitEvent: false });
  }
}
