<ng-container *transloco="let t; read: 'users.account-access.role-mappings'">
  <div class="flex flex-col h-full">
    <h2 *ngIf="header" class="mat-subtitle-1 mb-6" data-testid="role-mapping-header">{{ header }}</h2>
    <hy-content-list data-testid="user-rights-list" class="h-full role-mapping" [dataSource]="datasource" [loading]="loading">
      <hy-content-list-column propertyName="name" [sortable]="true" label="{{ t('role-column-label') }}">
        <ng-container *hyContentListCell="let row">
          <hy-content-list-truncated-text>{{ row.name }}</hy-content-list-truncated-text>
        </ng-container>
      </hy-content-list-column>
      <hy-content-list-column propertyName="userGroupsLabel" label="{{ t('user-groups-column-label') }}">
        <ng-container *hyContentListCell="let row">
          <div class="flex flex-row">
            <hy-content-list-truncated-text>{{ row.userGroupsLabel }}</hy-content-list-truncated-text>
          </div>
        </ng-container>
      </hy-content-list-column>
      <hy-content-list-column propertyName="userGroupsManage">
        <ng-container *hyContentListCell="let row">
          <button type="button" mat-button color="primary" (click)="editGroupsForARole(row.key, row.name, row.userGroups)">
            {{ t('manage-button-text') }}
          </button>
        </ng-container>
      </hy-content-list-column>
    </hy-content-list>
  </div>
</ng-container>
