import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HyMaterialModule } from '@hyland/ui';
import { ReusableFeatureFlagControl } from '../reusable-feature-flag-control';

@Component({
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule, HyMaterialModule],
  templateUrl: './feature-flag-boolean-form-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FeatureFlagBooleanFormTypeComponent,
      multi: true,
    },
  ],
})
export class FeatureFlagBooleanFormTypeComponent extends ReusableFeatureFlagControl {
  readonly control = new FormControl(true);
}
