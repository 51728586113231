import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EventDataRoutingModule } from './event-data-routing.module';
import { EventDataView } from './event-data.view';

@NgModule({
  declarations: [EventDataView],
  imports: [CommonModule, EventDataRoutingModule, MatProgressSpinnerModule],
  exports: [EventDataView],
})
export class EventDataModule {}
