import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Configuration, ConfigurationOptions } from './configuration';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private _appConfig: Configuration;
  private _configUpdateSubject$: ReplaySubject<Configuration>;
  configUpdate$: Observable<Configuration>;

  constructor(private _httpBackend: HttpBackend) {
    this._appConfig = new Configuration();
    this._configUpdateSubject$ = new ReplaySubject<Configuration>();
    this.configUpdate$ = this._configUpdateSubject$.asObservable();
  }

  /*
   * https://ui.hyland.io/5932dab8d/p/109a84-configuration/b/803964
   * Dynamic Configuration: Requesting a Remote Resource for HyuShell
   */

  environmentLoader(): Promise<ConfigurationOptions> {
    const CONFIG_URL = 'config/config.json';
    const httpClient = new HttpClient(this._httpBackend);
    return lastValueFrom(httpClient.get<ConfigurationOptions>(CONFIG_URL).pipe(shareReplay(1)));
  }

  async loadAppConfig() {
    const env = await this.environmentLoader();
    if (env) {
      this._appConfig = new Configuration(env);
    }

    this._configUpdateSubject$.next(this._appConfig);

    return this._appConfig;
  }

  getConfig() {
    return this._appConfig;
  }
}
