import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EventDataHandlerService,
  EventInformation,
} from '../../services/event-data-handler/event-data-handler.service';

@Component({
  templateUrl: './event-data.view.html',
  styleUrls: ['./event-data.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDataView implements OnInit {
  constructor(private _eventDataHandlerService: EventDataHandlerService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.handleUrlEventData();
  }

  handleUrlEventData(): void {
    this._route.params.subscribe((params) => {
      const eventInformation: EventInformation = {
        eventType: params.eventType,
        eventData: this._eventDataHandlerService.decodeUrlEventData(params.eventData),
      };
      this._eventDataHandlerService.emitEventInformation(eventInformation);
    });
  }
}
