import { FocusableOption } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { HyMaterialModule } from '@hyland/ui';

/* cspell:words tabindex */

@Component({
  selector: 'hxp-kernel-keyboard-nav-list-item',
  templateUrl: './keyboard-nav-list-item.component.html',
  styleUrls: ['./keyboard-nav-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, MatListModule, HyMaterialModule],
})
export class KeyboardNavListItemComponent implements FocusableOption {
  private readonly _element = inject(ElementRef<HTMLElement>);
  private readonly _cdr = inject(ChangeDetectorRef);

  @HostBinding('attr.tabindex')
  tabindex = '-1';

  @Input()
  routerLink!: string;

  @Input()
  displayName!: string;

  @Input()
  isActiveFocusIndex!: boolean;

  @Input()
  testId?: string;

  getLabel(): string {
    return this.displayName;
  }

  focus() {
    this._element.nativeElement.querySelector('a')?.focus();
  }

  markForCheck() {
    this._cdr.markForCheck();
  }
}
