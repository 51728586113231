import { Component, OnDestroy, ViewChild, inject, signal } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountDetailSubscriptionsGQL, SubscriptionStatus, SubscriptionType } from '@hxp/graphql';
import { SubscriptionStatusPollingService } from '@hxp/nucleus';
import { HyContentListComponent, HyContentListDataSource } from '@hyland/ui';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

export interface AccountSubscription {
  id: string;
  title: string;
  status: SubscriptionStatus;
  isTrial: boolean;
  environmentName?: string;
  renewalDate?: Date;
  __typename?: string;
}

type State = 'loading' | 'loaded' | 'empty';

@Component({
  selector: 'hxp-account-details-subscriptions',
  templateUrl: './account-details-subscriptions.view.html',
  styleUrls: ['./account-details-subscriptions.view.scss'],
})
export class AccountDetailsSubscriptionsView implements OnDestroy {
  @ViewChild(HyContentListComponent, { static: false })
  subscriptionList?: HyContentListComponent;

  private readonly accountSubscriptionQuery = inject(AccountDetailSubscriptionsGQL);
  private readonly _subscriptionStatusPolling = inject(SubscriptionStatusPollingService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  readonly state = signal<State>('loading');

  readonly subscriptions$: Observable<AccountSubscription[]> = this.accountSubscriptionQuery
    .watch(undefined, { useInitialLoading: true })
    .valueChanges.pipe(
      map((result) => {
        if (!result.data) {
          return [];
        }

        const subs = result.data.currentUser.homeAccount.account.subscriptions;
        const loadedState = subs.length > 0 ? 'loaded' : 'empty';

        this.state.set(result.loading ? 'loading' : loadedState);

        return subs.map((sub) => {
          if ((sub.status === SubscriptionStatus.Draft && sub.environment) || sub.status === SubscriptionStatus.Provisioning) {
            this._subscriptionStatusPolling.startPollingForSubscription(sub.id);
          }

          return {
            id: sub.id,
            title: sub.title,
            status: sub.status,
            environmentName: sub.environment ? sub.environment.name : undefined,
            isTrial: sub.type === SubscriptionType.Trial,
            __typename: sub.__typename,
          } as AccountSubscription;
        });
      }),
    );

  selectedId = this._route.snapshot.firstChild?.firstChild?.params.id;
  selectedSub?: AccountSubscription;
  sortColumn = 'title';
  sortDirection: SortDirection = 'asc';
  subscriptionDatasource = new HyContentListDataSource<AccountSubscription>();
  hideInactiveSubs$ = new BehaviorSubject<boolean>(true);
  initialToggleCheckedValue = true;
  SubscriptionStatus = SubscriptionStatus;

  private readonly _unsubscribe$ = new Subject<void>();

  constructor() {
    this._fixDatasourceSorting();

    // Set the active subscription id for deep linking
    // the active sub will be updated based on this id when the query
    // loads

    combineLatest([this.hideInactiveSubs$, this.subscriptions$])
      .pipe(
        takeUntil(this._unsubscribe$),
        tap(([hideInactiveSubs, subscriptions]) => {
          if (this.selectedId) {
            this.selectedSub = subscriptions.find((s) => s.id === this.selectedId);

            if (!this.selectedSub) {
              // subscription not found remove id from route
              this.selectedId = undefined;
              this.selectedSub = undefined;
              void this._router.navigate(['.'], { relativeTo: this._route });
            } else {
              if (this.selectedSub.status === SubscriptionStatus.Cancelled || this.selectedSub.status === SubscriptionStatus.Expired) {
                this.initialToggleCheckedValue = false;
                hideInactiveSubs = false;
              }
            }
          }

          this.subscriptionDatasource.data = hideInactiveSubs ? this.filterCancelledAndExpiredSubscriptions(subscriptions) : subscriptions;

          if (this.selectedSub) {
            // Using requestAnimationFrame because it was not scrolling to the row since
            // the content list didn't receive the updated data yet.  setTimeout would work
            // too, but would show the first row before scrolling
            requestAnimationFrame(() => {
              this.subscriptionList?.scrollToActiveRow();
            });
          }
        }),
      )
      .subscribe();

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((route) => {
        // If you cancel out of a subscription detail's panel (which is a child route), we
        // have to unselect the subscription in this parent route
        if (!route.snapshot.firstChild?.firstChild?.params.id) {
          this.selectedId = undefined;
          this.selectedSub = undefined;
        }
      });
  }

  filterCancelledAndExpiredSubscriptions(subs: AccountSubscription[]): AccountSubscription[] {
    return subs.filter((s) => s.status !== SubscriptionStatus.Cancelled && s.status !== SubscriptionStatus.Expired);
  }

  onInactiveSubsToggleChange(checked: boolean) {
    this.hideInactiveSubs$.next(checked);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  handleRowSelection(activeSubscription: AccountSubscription) {
    if (activeSubscription) {
      this.selectedSub = activeSubscription;
      void this._router.navigate(['subscriptions', activeSubscription.id], { relativeTo: this._route });
    }
  }

  private _fixDatasourceSorting() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.subscriptionDatasource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }
}
