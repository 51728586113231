import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filterOutTemplateApps } from '@hxp/admin/applications/utils';
import { EnvironmentApplicationListGQL, EnvironmentApplicationListQuery, SubscriptionStatus } from '@hxp/graphql';
import { EnvironmentContextService, routeParameter } from '@hxp/kernel';
import { SubscriptionApp } from '@hxp/nucleus';
import { HyTranslateService } from '@hyland/ui';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

interface SubscriptionListItem {
  readonly title: string;
  readonly apps: SubscriptionApp[];
}

type Environment = EnvironmentApplicationListQuery['currentUser']['homeAccount']['account']['environment'];

@Component({
  templateUrl: './apps.view.html',
  styleUrls: ['./apps.view.scss'],
})
export class AppsView implements OnInit, OnDestroy {
  selectedAppId?: string;
  subscriptions$: Observable<SubscriptionListItem[]>;

  private readonly _unsubscribe$ = new Subject<void>();

  readonly loading = signal(false);

  constructor(
    private readonly _title: Title,
    private readonly _appsService: EnvironmentApplicationListGQL,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _environmentContext: EnvironmentContextService,
    private readonly _translate: HyTranslateService,
  ) {
    const snapshotEnvironmentId = this._route.parent?.snapshot.paramMap.get('environmentId');
    if (snapshotEnvironmentId && snapshotEnvironmentId !== this._environmentContext.getSelectedEnvironmentValue()) {
      this._environmentContext.selectEnvironment(snapshotEnvironmentId);
    }

    this.subscriptions$ = this._route.params.pipe(
      routeParameter<string>('environmentId'),
      switchMap((environmentIdFromRoute) => {
        return this._appsService.watch({ id: environmentIdFromRoute }, { useInitialLoading: true }).valueChanges.pipe(
          tap((res) => {
            if (res.data) {
              this.handleSelectedEnvironment(res.data.currentUser.homeAccount.account.environment);
            }
          }),
          map((res) => {
            this.loading.set(res.loading);

            if (!res.data) {
              return [];
            }

            const subscriptions = res.data.currentUser.homeAccount.account.environment?.subscriptions || [];

            return subscriptions.reduce((subscriptionList, subscription) => {
              if (subscription.status === SubscriptionStatus.Active || subscription.status === SubscriptionStatus.Provisioning) {
                const subscribedApps = filterOutTemplateApps(subscription.subscribedApps);

                const subscriptionListItem: SubscriptionListItem = {
                  title: subscription.title,
                  apps: subscribedApps.map((a) => ({ id: a.id, name: a.app.localizedName, key: a.app.key })),
                };

                return [...subscriptionList, subscriptionListItem];
              }

              return subscriptionList;
            }, new Array<SubscriptionListItem>());
          }),
        );
      }),
    );

    this.trackSelectedApp();
  }

  private handleSelectedEnvironment(environment: Environment) {
    if (environment && environment.id !== this._environmentContext.getSelectedEnvironmentValue()) {
      this._environmentContext.selectEnvironment(environment.id);
    }
  }

  trackSelectedApp() {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap(() => this._route.firstChild!.paramMap),
        map((p) => p.get('appId') || ''),
        distinctUntilChanged(),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((selectedAppId) => (this.selectedAppId = selectedAppId));
  }

  ngOnInit() {
    this._title.setTitle(this._translate.get('nucleus.page-title.applications'));

    // If  the environmentId changes while on this route or any child, you need to route back to the base
    // '/e nvironments/{environmentId}/apps' path with the new environmentId
    this._environmentContext.selectedEnvironment$
      .pipe(
        tap((environmentId) => {
          const environmentIdFromRoute = this._route.parent?.snapshot.paramMap.get('environmentId');

          if (environmentIdFromRoute && environmentIdFromRoute !== environmentId) {
            void this._router.navigateByUrl(`account/environment/${environmentId}/apps`);
          }
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
