import { PageInfo } from '@hxp/graphql';
import { BehaviorSubject, Observable, catchError, concatMap, filter, map, of, scan, takeWhile, tap, throwError } from 'rxjs';

export interface PaginatedData<T> {
  readonly pageInfo?: PageInfo | null;
  readonly nodes: readonly T[];
}

export enum PaginationState {
  Initial = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  Empty = 'empty',
  Error = 'error',
}

export abstract class PaginationService<Filters extends { readonly first: number; readonly after?: string | null }, Node> {
  abstract fetchData(filters: Filters): Observable<PaginatedData<Node>>;

  private readonly _stateSub$ = new BehaviorSubject<PaginationState>(PaginationState.Initial);
  private readonly _currentPageIndex$ = new BehaviorSubject<number>(0);
  readonly state$ = this._stateSub$.asObservable();

  changePageIndex(index: number): void {
    this._currentPageIndex$.next(index);
  }

  getNodes(filters: Filters): Observable<readonly Node[]> {
    const cachedPages = new Set<number>();
    let cursor: string | null | undefined = null;

    // Reset the pageIndex when this method is called.
    // This prevents retaining the original pageIndex that the user was on prior to changing the filters, ensuring accurate pagination.
    this._currentPageIndex$.next(0);

    return this._currentPageIndex$.pipe(
      // only fetch pages that are not already fetched
      filter((pageIndex) => !cachedPages.has(pageIndex)),
      concatMap((pageIndex) => {
        cachedPages.add(pageIndex);
        const isFirstCall = cachedPages.size === 1;
        return isFirstCall ? this._firstCallHandler(filters) : this._fetchNextPage(filters, cursor);
      }),
      tap(({ pageInfo }) => {
        cursor = pageInfo?.endCursor;
      }),

      // Close the stream if there are no more pages to fetch for the current filters.
      // This will stop listening for new pageIndex changes.
      // However, the accumulated data will still be available for navigation back and forth in the UI.
      takeWhile(({ pageInfo }) => !!pageInfo?.hasNextPage, true),
      scan((acc, value) => [...acc, ...value.nodes], [] as readonly Node[]),
      tap((nodes) => {
        this.setLoadedState(nodes.length);
      }),
    );
  }

  /**
   * This handler is used for the first call when fetching data.
   * It ensures that the "Go to Next Page" functionality in the UI is active.
   * Without this handler, if the pagination size is equal to the number of elements fetched,
   * the "Go to Next Page" button would be inactive as the component would think there is no more elements to show.
   * By fetching the second page in advance, we can enable the "Go to Next Page" button accordingly.
   */
  private _firstCallHandler(filters: Filters): Observable<PaginatedData<Node>> {
    // eslint-disable-next-line no-null/no-null
    return this._fetchNextPage(filters, null).pipe(
      concatMap((data) => {
        const hasNextPage = !!data.pageInfo?.hasNextPage;

        if (hasNextPage) {
          return this._fetchNextPage(filters, data.pageInfo.endCursor).pipe(
            map((secondPageData) => ({
              pageInfo: secondPageData.pageInfo,
              nodes: [...data.nodes, ...secondPageData.nodes],
            })),
          );
        }
        return of(data);
      }),
    );
  }

  private setLoadedState(length: number): void {
    const state = length === 0 ? PaginationState.Empty : PaginationState.Loaded;
    this._stateSub$.next(state);
  }

  // eslint-disable-next-line no-null/no-null
  private _fetchNextPage(filters: Filters, cursor: string | null = null) {
    const filtersWithCursor: Filters = cursor ? { ...filters, after: cursor } : filters;
    this._stateSub$.next(PaginationState.Loading);

    return this.fetchData(filtersWithCursor).pipe(
      catchError((error) => {
        this._stateSub$.next(PaginationState.Error);
        return throwError(() => error);
      }),
    );
  }
}
