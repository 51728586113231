import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KernelComponentsModule } from '@hxp/kernel';

@Component({
  standalone: true,
  selector: 'hxp-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, KernelComponentsModule],
})
export class CardFooterComponent {
  @Input() submitting = false;
  @Input() showProgressBar = false;
}
