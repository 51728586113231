import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureFlagsComponent } from './components/feature-flags/feature-flags.component';
import { FeatureFlagStrategy } from './services/feature-flag.strategy';
import { FeatureFlagStrategyLocal } from './services/feature-flags-local/feature-flags-local.strategy';
import { FeatureFlagsOverrideService } from './services/feature-flags-override/feature-flags-override.service';
import { FeatureFlagStrategyServer } from './services/feature-flags-server/feature-flags-server.strategy';

interface Config {
  readonly useLocalStrategy: boolean;
}

@NgModule({
  imports: [FeatureFlagsComponent],
  providers: [FeatureFlagsOverrideService],
  exports: [FeatureFlagsComponent],
})
export class FeatureFlagsModule {
  static forRoot(config: Config): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [
        { provide: Storage, useValue: localStorage },
        ...(config.useLocalStrategy
          ? [{ provide: FeatureFlagStrategy, useClass: FeatureFlagStrategyLocal }]
          : [{ provide: FeatureFlagStrategy, useClass: FeatureFlagStrategyServer }]),
      ],
    };
  }
}
