import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventDataHandlerService } from '@hxp/kernel';
import { filter } from 'rxjs/operators';

export enum AdminPortalUrlEventTypes {
  ActivateSubscription = 'activate-subscription',
}

export interface ActivateSubscriptionEventData {
  subscriptionId: number;
}

@Injectable({
  providedIn: 'root',
})
export class AdminUrlEventDataService {
  constructor(private _eventDataHandler: EventDataHandlerService, private _router: Router) {}

  handleSubscriptionActivation() {
    this._eventDataHandler.eventInformation$
      .pipe(
        filter((eventInformation) => {
          return eventInformation.eventType === AdminPortalUrlEventTypes.ActivateSubscription && Boolean(eventInformation.eventData);
        }),
      )
      .subscribe((eventInformation) => {
        const eventData = eventInformation.eventData as ActivateSubscriptionEventData;
        if (eventData.subscriptionId) {
          this._navigateToSubscription(eventData.subscriptionId);
        }
      });
  }

  private _navigateToSubscription(subscriptionId: number) {
    void this._router.navigate([`/account/account-details/subscriptions/${subscriptionId}`]);
  }
}
