<div role="banner" class="banner-container" data-testid="dashboard-welcome-banner">
  <div class="flex justify-between">
    <svg width="385" height="79" viewBox="0 0 385 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M382 -362V22.8504C382 52.1934 358.227 76 328.874 76H-95" stroke="#EE8655" stroke-width="6" stroke-miterlimit="10" />
    </svg>
    <svg class="absolute right-0 bottom-0" width="661" height="153" viewBox="0 0 661 153" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M102.25 132C102.25 140.683 109.317 147.75 118 147.75C126.683 147.75 133.75 140.683 133.75 132C133.75 123.317 126.683 116.25 118 116.25C109.317 116.25 102.25 123.317 102.25 132Z"
        fill="white"
        stroke="#003544"
        stroke-width="7.5"
      />
      <path d="M301 3V132H428V3H301Z" fill="#00586E" stroke="#599A7B" stroke-width="6" stroke-miterlimit="10" />
      <path d="M830 132L140 132" stroke="#599A7B" stroke-width="6" stroke-miterlimit="10" />
      <path d="M431 259C501.153 259 558 202.13 558 132H431V259Z" stroke="#FFB51B" stroke-width="6" stroke-miterlimit="10" />
    </svg>
  </div>
</div>
