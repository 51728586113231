import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HySwitchComponent, HySwitchModule } from '@hyland/ui/switch';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'hxp-kernel-on-off-switch',
  standalone: true,
  imports: [CommonModule, HySwitchModule, TranslocoModule],
  templateUrl: './on-off-switch.component.html',
  styleUrls: ['./on-off-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnOffSwitchComponent implements AfterViewInit {
  @ViewChild('switchRef') element?: TemplateRef<HySwitchComponent>;

  @Input() label?: string;
  @Input() ariaLabel?: string;
  @Input({ required: true }) checked!: boolean | null;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<boolean>();

  labelId?: string;
  buttonId?: string;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    void Promise.resolve().then(() => {
      const switchId = (this.element as HySwitchComponent | undefined)?.id ?? '';
      this.labelId = `${switchId}-label`;
      this.buttonId = `${switchId}-button`;

      const elRef = this.element?.elementRef as ElementRef<HTMLElement> | undefined;
      const button = elRef?.nativeElement.querySelector('button') as HTMLButtonElement | undefined;

      if (button) {
        this._renderer.setAttribute(button, 'id', this.buttonId);
        this._cdr.detectChanges();
      }
    });
  }
}
