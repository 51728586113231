<ng-container [formGroup]="form">
  <div class="flex flex-row justify-between items-center pt-4 px-6">
    <h2 class="mb-0 pb-0">Feature Flags Override</h2>
  </div>

  <div mat-dialog-content class="py-6 border-t box-border grow h-full overflow-hidden max-h-full">
    <div class="flex flex-col h-full gap-4 box-border">
      <hy-feedback-banner type="info" message="Refresh the page after making changes to see the effect."> </hy-feedback-banner>

      <div class="container-border grow overflow-y-auto">
        <div formArrayName="flags" class="w-full">
          <div
            class="flex flex-row flex-nowrap gap-4 w-full justify-between items-center px-4 py-2 flag-border box-border"
            *ngFor="let flag of flags.controls"
            [formGroup]="flag"
          >
            <p class="w-4/12 text-base m-0 text-neutral-600">{{ flag.controls.flagKey.value }}</p>
            <div class="value w-4/12 flex flex-col jusify-center items-end leading-6">
              <ng-template [flagType]="flag.controls.type.value" hxpKernelFeatureFlagFormType formControlName="value"></ng-template>
              <span
                class="mt-4 text-sm text-primary-500 hover:underline hover:cursor-pointer"
                *ngIf="isOverridenFlag(flag.controls.flagKey.value)"
                (click)="removeFlagOverride(flag.controls.flagKey.value)"
                >remove flag override</span
              >
              <span class="text-sm">original value: <strong>{{ getOriginalFlagValue(flag.controls.flagKey.value) }}</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
