import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject, tap, withLatestFrom } from 'rxjs';

export interface WizardButtonProps {
  readonly label: string;
  readonly action: () => void;
}

interface State {
  readonly next?: WizardButtonProps;
  readonly previous?: WizardButtonProps;
  readonly cancel?: WizardButtonProps;
  readonly closeDialogAction?: () => void;
}

const initialState: State = {};

@Injectable()
export class DialogWithWizardStore extends ComponentStore<State> {
  constructor() {
    super(initialState);
  }

  private readonly closeDialogSub$ = new Subject<void>();
  readonly closeDialog$ = this.closeDialogSub$.asObservable();

  readonly next$ = this.select((state) => state.next);
  readonly nextLabel$ = this.selectSignal((state) => state.next?.label);
  readonly previous$ = this.select((state) => state.previous);
  readonly previousLabel$ = this.selectSignal((state) => state.previous?.label);
  readonly cancel$ = this.select((state) => state.cancel);
  readonly cancelLabel$ = this.selectSignal((state) => state.cancel?.label);
  readonly closeDialogAction$ = this.select((state) => state.closeDialogAction);

  readonly setCloseDialogAction = this.updater((state, closeDialogAction: () => void): State => ({ ...state, closeDialogAction }));
  readonly setNextButtonState = this.updater((state, next: WizardButtonProps | undefined): State => ({ ...state, next }));
  readonly setPreviousButtonState = this.updater((state, previous: WizardButtonProps | undefined): State => ({ ...state, previous }));
  readonly setCancelButtonState = this.updater((state, cancel: WizardButtonProps | undefined): State => ({ ...state, cancel }));
  readonly hideCancelButton = this.updater((state): State => {
    const cancel = state.cancel;

    if (!cancel) {
      return state;
    }

    return { ...state, cancel: { ...cancel, label: '' } };
  });

  readonly closeDialog = this.effect((origin$) =>
    origin$.pipe(
      withLatestFrom(this.closeDialogAction$),
      tap(([_, action]) => action && action()),
    ),
  );

  readonly callNext = this.effect((origin$) => origin$.pipe(callButtonAction(this.next$)));
  readonly callPrevious = this.effect((origin$) => origin$.pipe(callButtonAction(this.previous$)));
  readonly callCancel = this.effect((origin$) => origin$.pipe(callButtonAction(this.cancel$)));
}

const callButtonAction = (buttonProps$: Observable<WizardButtonProps | undefined>) => (actionProps$: Observable<void>) =>
  actionProps$.pipe(
    withLatestFrom(buttonProps$),
    tap(([_, button]) => button?.action()),
  );
