import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hxp-kernel-content-cannot-be-viewed',
  templateUrl: './content-cannot-be-viewed.component.html',
  styleUrls: ['./content-cannot-be-viewed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCannotBeViewedComponent {
  @Input()
  appType = 'Home Page';

  @Input()
  showGoToButton = true;
}
