import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { HyGhostModule, HyMaterialListModule } from '@hyland/ui';

@Component({
  selector: 'hxp-kernel-app-mat-list-ghost-loader',
  templateUrl: './mat-list-ghost-loader.component.html',
  styleUrls: ['./mat-list-ghost-loader.component.scss'],
  standalone: true,
  imports: [CommonModule, HyGhostModule, MatListModule, HyMaterialListModule],
})
export class MatListGhostLoaderComponent {}
