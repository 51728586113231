import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureFlagContainer, FeatureFlagResponse, FlagAdditionalContext } from '../../models/feature-flag';
import { FeatureFlagStrategy } from '../feature-flag.strategy';
import { FeatureFlagsOverrideService } from '../feature-flags-override/feature-flags-override.service';

export const ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN = new InjectionToken<FeatureFlagContainer>('Available feature flags');

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    private readonly _featureFlagStrategy: FeatureFlagStrategy,
    private readonly _overrideService: FeatureFlagsOverrideService,
  ) {}

  getFlag(flagKey: string, context?: FlagAdditionalContext): Observable<FeatureFlagResponse> {
    const overriddenFlag = this._overrideService.getFlagValue(flagKey);

    if (overriddenFlag !== undefined) {
      return of({ value: overriddenFlag });
    }

    return this._featureFlagStrategy.getFlag(flagKey, context);
  }

  getAllFlags(flags: string[]) {
    return this._featureFlagStrategy.getAllFlags(flags);
  }
}
