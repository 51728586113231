import { AppType } from '@hxp/graphql';

interface SubscribedApp {
  readonly app: App;
}

interface App {
  readonly appType: AppType;
}

export const filterOutTemplateApps = <T extends SubscribedApp>(subscribedApps: T[] | undefined): T[] => {
  if (!subscribedApps) {
    return [];
  }

  return subscribedApps.filter(({ app }) => app.appType !== AppType.Template);
};
