import { Injectable } from '@angular/core';
import { NewSubscriptionsGQL } from '@hxp/graphql';
import { HyLocalStorageService } from '@hyland/ui';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewSubscriptionDialogService {
  showNewSubscriptionDialog$: Observable<boolean>;

  private readonly _latestUnassignedKey = 'hxp:latest-unassigned-subscription';
  private readonly _ignoreNewSubscriptionKey = 'hxp:ignore-new-subscription';

  constructor(private readonly _hyLocalStorage: HyLocalStorageService, newSubscriptionQuery: NewSubscriptionsGQL) {
    this.showNewSubscriptionDialog$ = newSubscriptionQuery.watch().valueChanges.pipe(
      filter((subscriptionsQueryResult) => !!subscriptionsQueryResult.data),
      take(1),
      map((subscriptionsQueryResult) => subscriptionsQueryResult.data.currentUser.homeAccount.account.subscriptions),
      map((subscriptions) => {
        const ignoreNewSubscription = _hyLocalStorage.getObject<boolean>(this._ignoreNewSubscriptionKey);
        if (ignoreNewSubscription) {
          return false;
        }

        const storedSubscriptionDate = _hyLocalStorage.getObject<Date>(this._latestUnassignedKey);
        const unassignedSubscriptions = subscriptions.filter((subscription) => !subscription.environment);
        const latestUnassignedSubscription = unassignedSubscriptions.length
          ? unassignedSubscriptions.reduce((latestSubscription, currentSubscription) =>
              currentSubscription.createdDate > latestSubscription.createdDate ? currentSubscription : latestSubscription,
            )
          : undefined;

        if (
          latestUnassignedSubscription &&
          (!storedSubscriptionDate || latestUnassignedSubscription.createdDate > storedSubscriptionDate)
        ) {
          _hyLocalStorage.setObject(this._latestUnassignedKey, latestUnassignedSubscription.createdDate);
          return true;
        }

        return false;
      }),
    );
  }

  ignoreNewSubscriptions() {
    this._hyLocalStorage.setObject(this._ignoreNewSubscriptionKey, true);
  }
}
