import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TRANSLATION_SCOPE } from '@hxp/shared/i18n';
import { HyTranslateService } from '@hyland/ui';

@Component({
  templateUrl: './home-dashboard.view.html',
  styleUrls: ['./home-dashboard.view.scss'],
  providers: [
    {
      provide: TRANSLATION_SCOPE,
      useValue: 'admin-portal.dashboard',
    },
  ],
})
export class HomeDashboardView implements OnInit {
  constructor(private _translate: HyTranslateService, private readonly _title: Title) {}

  ngOnInit() {
    this._title.setTitle(this._translate.get('admin-portal.page-title.dashboard'));
  }
}
