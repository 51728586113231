import { Injectable } from '@angular/core';
import { HyLocalStorageService } from '@hyland/ui';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentContextService {
  private readonly _selectedEnvironment$ = new ReplaySubject<string>(1);
  readonly selectedEnvironment$ = this._selectedEnvironment$.asObservable().pipe(distinctUntilChanged());

  private readonly _localStorageKey = 'hxp:environment-context';

  getSelectedEnvironmentValue() {
    let selectedEnvironmentValue: string | undefined;
    this.selectedEnvironment$
      .pipe(take(1))
      .subscribe((environment) => (selectedEnvironmentValue = environment))
      .unsubscribe();
    return selectedEnvironmentValue;
  }

  constructor(private readonly _hyLocalStorage: HyLocalStorageService) {
    const storedEnvironmentId = _hyLocalStorage.getObject<string>(this._localStorageKey);
    if (storedEnvironmentId) {
      this._selectedEnvironment$.next(storedEnvironmentId);
    }
  }

  selectEnvironment(environmentId: string) {
    this._hyLocalStorage.setObject(this._localStorageKey, environmentId);
    this._selectedEnvironment$.next(environmentId);
  }
}
