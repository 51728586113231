import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { GhostModule, KeyboardNavListModule, SharedLoadersModule } from '@hxp/kernel';
import { HyGhostModule, HyMaterialModule, HyTranslateModule } from '@hyland/ui';
import { AccountAccessModule } from '../account-access/account-access.module';
import { AppsRoutingModule } from './apps-routing.module';
import { AppDetailsView } from './views/app-details/app-details.view';
import { AppUserRightsView } from './views/app-user-rights/app-user-rights.view';
import { ApplicationOverviewView } from './views/application-overview/application-overview.view';
import { AppsView } from './views/apps/apps.view';

@NgModule({
  declarations: [AppsView, AppDetailsView, ApplicationOverviewView, AppUserRightsView],
  imports: [
    CommonModule,
    MatListModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    FormsModule,
    HyMaterialModule,
    HyGhostModule,
    MatFormFieldModule,
    MatInputModule,
    AppsRoutingModule,
    AccountAccessModule,
    HyTranslateModule,
    SharedLoadersModule,
    KeyboardNavListModule,
    GhostModule,
  ],
})
export class AppsModule {}
