import { Directive, Injector, Input, Type, ViewContainerRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { FeatureFlagBooleanFormTypeComponent } from './feature-flag-boolean-form-type/feature-flag-boolean-form-type.component';
import { FeatureFlagJsonFormTypeComponent } from './feature-flag-json-form-type/feature-flag-json-form-type.component';
import { FeatureFlagNumberFormTypeComponent } from './feature-flag-number-form-type/feature-flag-number-form-type.component';
import { FeatureFlagStringFormTypeComponent } from './feature-flag-string-form-type/feature-flag-string-form-type.component';
import { ReusableFeatureFlagControl } from './reusable-feature-flag-control';

type FlagType = 'boolean' | 'number' | 'string' | 'object';

@Directive({
  selector: '[hxpKernelFeatureFlagFormType]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FeatureFlagFormTypeDirective,
      multi: true,
    },
  ],
})
export class FeatureFlagFormTypeDirective {
  @Input()
  get flagType(): FlagType {
    return this._flagType;
  }
  set flagType(value: FlagType) {
    this._flagType = value;
    this.loadComponent();
  }
  private _flagType!: FlagType;

  private readonly _components: { readonly [key in FlagType]?: Type<ReusableFeatureFlagControl> } = {
    // eslint-disable-next-line id-blacklist
    boolean: FeatureFlagBooleanFormTypeComponent,
    // eslint-disable-next-line id-blacklist
    number: FeatureFlagNumberFormTypeComponent,
    // eslint-disable-next-line id-blacklist
    string: FeatureFlagStringFormTypeComponent,
    object: FeatureFlagJsonFormTypeComponent,
  };

  constructor(private readonly _viewContainerRef: ViewContainerRef, private readonly _injector: Injector) {}

  loadComponent(): void {
    if (!this.flagType) {
      return;
    }

    const componentRef = this._components[this.flagType];
    this._viewContainerRef.clear();

    if (!componentRef) {
      const supportedTypes = Object.keys(this._components).join(', ');
      throw new Error(`Unsupported type "${this.flagType}". Currently Supported types: ${supportedTypes}`);
    }

    const control = this._injector.get(NgControl);
    const valueAccessor = control.valueAccessor as ReusableFeatureFlagControl;
    const component = this._viewContainerRef.createComponent(componentRef);

    component.instance.registerOnChange(valueAccessor.onChange);
    control.valueAccessor = component.instance;
  }
}
