import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SAVER, ServerErrorHandlerService, getSaver } from '@hxp/admin/shared/services';
import {
  ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN,
  ANALYTICS_CONFIG,
  ANALYTICS_SERVICE,
  AuthService,
  CONFIG_PROVIDERS,
  ConfigurationService,
  EnvironmentContextModule,
  EventDataModule,
  FeatureFlagDirective,
  FeatureFlagsModule,
  GraphQLModule,
  KernelComponentsModule,
  PendoAnalyticsService,
  USE_FAKE_AUTH_TOKEN,
} from '@hxp/kernel';
import { DashboardModule } from '@hxp/nucleus';
import { TranslatedToastService } from '@hxp/shared/i18n';
import { HyAuthService, HyDialogModule, HyGhostModule, HyMaterialIconModule, HyMaterialModule, HyMaterialTabsModule } from '@hyland/ui';
import { HyHeaderModule, HyShellModule } from '@hyland/ui-shell';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DiscardChangesDialog } from './components/discard-changes-dialog/discard-changes.dialog';
import { NewSubscriptionDialogService } from './components/new-subscription-dialog/new-subscription-dialog.service';
import { NewSubscriptionDialog } from './components/new-subscription-dialog/new-subscription.dialog';
import { ContentCannotBeViewedView } from './views/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { HomeDashboardView } from './views/home-dashboard/home-dashboard.view';
import { UnauthorizedView } from './views/unauthorized/unauthorized.view';
import { UserOperationsModule } from './views/users/components/user-operations';

@NgModule({
  declarations: [AppComponent, HomeDashboardView, ContentCannotBeViewedView, NewSubscriptionDialog, UnauthorizedView, DiscardChangesDialog],
  imports: [
    HyShellModule.forRoot(),
    EventDataModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    GraphQLModule,
    HttpClientModule,
    HyDialogModule,
    HyGhostModule,
    HyMaterialIconModule,
    KernelComponentsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatRippleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    EnvironmentContextModule,
    UserOperationsModule,
    MatExpansionModule,
    HyMaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTabsModule,
    HyMaterialTabsModule,
    FeatureFlagDirective,
    FeatureFlagsModule.forRoot({ useLocalStrategy: environment.useLocalFeatureFlags }),
    HyHeaderModule,
  ],
  providers: [
    ...CONFIG_PROVIDERS,
    NewSubscriptionDialogService,
    DatePipe,
    { provide: SAVER, useFactory: getSaver },
    ServerErrorHandlerService,
    TranslatedToastService,
    { provide: USE_FAKE_AUTH_TOKEN, useValue: environment.useFakeAuth },
    { provide: HyAuthService, useExisting: AuthService },
    {
      provide: ANALYTICS_CONFIG,
      useFactory: (config: ConfigurationService) => {
        const c = config.getConfig();
        return c.analytics;
      },
      deps: [ConfigurationService],
    },
    { provide: ANALYTICS_SERVICE, useClass: PendoAnalyticsService },
    {
      provide: ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN,
      useValue: ['nucleus-ce-show-override-flag-options', 'ce-backlog'],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
