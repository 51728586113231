import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, scan } from 'rxjs';
import { SAVER, Saver } from './saver.provider';

export interface Download {
  readonly state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  readonly content: Blob | undefined;
  readonly progress: number;
  readonly bytesLoaded: number;
}

export interface DownloadProps {
  readonly endpoint: string;
  readonly filename: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly body: any;
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver,
  ) {
    this.estimatedTotal = NaN;
  }
  estimatedTotal: number;

  download({ endpoint, filename, body }: DownloadProps): Observable<Download> {
    return this.http
      .post(endpoint, body, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(this.innerDownload((blob) => this.save(blob, filename)));
  }

  private innerDownload(saver?: (blob: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    // eslint-disable-next-line rxjs/finnish
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (previous: Download, event: HttpEvent<Blob>): Download => {
            switch (event.type) {
              case HttpEventType.ResponseHeader: {
                this.estimatedTotal = Number(event.headers.get('estimated-content-length'));
                break;
              }
              case HttpEventType.DownloadProgress: {
                const total = event.total || this.estimatedTotal;
                let progress = total ? Math.round((100 * event.loaded) / total) : previous.progress;
                progress = Math.min(Math.max(progress, 0), 100);
                return {
                  state: 'IN_PROGRESS',
                  progress,
                  content: undefined,
                  bytesLoaded: event.loaded,
                };
              }
              case HttpEventType.Response: {
                if (saver && event.body) {
                  saver(event.body);
                  return {
                    state: 'DONE',
                    progress: 100,
                    content: event.body,
                    bytesLoaded: event.body.size,
                  };
                }
                break;
              }
              default: {
                break;
              }
            }
            return previous;
          },
          { state: 'PENDING', progress: 0, content: undefined, bytesLoaded: 0 },
        ),
      );
  }
}
