/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({})
export abstract class ReusableFeatureFlagControl implements ControlValueAccessor, OnInit, OnDestroy {
  abstract readonly control: FormControl;
  protected readonly _unsubscribe$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};
  onChange!: (value: any) => void;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(startWith(this.control.value), takeUntil(this._unsubscribe$)).subscribe((v) => {
      this.onChange(v);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  writeValue(obj: any): void {
    this.control.patchValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDisabled ? this.control.disable() : this.control.enable();
  }
}
