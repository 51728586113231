<mat-card class="hxp-card border-none">
  <mat-card-header class="hxp-card__header">
    <ng-content select="hxp-card-header"></ng-content>
  </mat-card-header>
  <mat-card-content class="hxp-card__content-container">
    <div class="hxp-card__content">
      <ng-content select="hxp-card-content"></ng-content>
    </div>
  </mat-card-content>
  <mat-card-footer class="hxp-card__footer border-foreground-divider">
    <ng-content select="hxp-card-footer"></ng-content>
  </mat-card-footer>
</mat-card>
