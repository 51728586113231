import { AnalyticsConfig, defaultAnalyticsConfig } from '../services/analytics/analytics';
import { autoImplement } from '../types';
import { OidcConfiguration, OidcConfigurationOptions } from './oidc-configuration';

export interface ConfigurationOptions {
  useFakeAuth: boolean;
  commitHash: string;
  production: boolean;
  HY_AUTHORIZATION_CONFIG: OidcConfigurationOptions;
  graphqlApi: string;
  auditEventsApi: {
    getAuditEventsFile: string;
  };
  termsConditionsUrl: string;
  privacyPolicyUrl: string;
  usagePolicyUrl: string;
  analytics: AnalyticsConfig;
  helpFiles?: HelpFiles;
}

export interface HelpFiles {
  readonly admin: string;
  readonly userPortal: string;
}

export class Configuration extends autoImplement<ConfigurationOptions>() {
  constructor(config?: Partial<ConfigurationOptions>) {
    super();

    this.useFakeAuth = config?.useFakeAuth || false;
    this.commitHash = config?.commitHash || '';
    this.production = config?.production || true;
    // can't use spread or object.assign or lodash merge since OidcConfiguration has property functions on it,
    // which won't be included in new combined object properly
    this.HY_AUTHORIZATION_CONFIG = new OidcConfiguration(config?.HY_AUTHORIZATION_CONFIG);
    this.graphqlApi = config?.graphqlApi || '';
    this.auditEventsApi = config?.auditEventsApi || {
      getAuditEventsFile: '',
    };
    this.termsConditionsUrl = config?.termsConditionsUrl || '';
    this.privacyPolicyUrl = config?.privacyPolicyUrl || '';
    this.usagePolicyUrl = config?.usagePolicyUrl || '';
    this.analytics = config?.analytics || defaultAnalyticsConfig;
    this.helpFiles = config?.helpFiles || { admin: '', userPortal: '' };
  }
}
