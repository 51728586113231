<ng-container *transloco="let t; read: 'nucleus.account-details.views.account-details'">
  <div class="h-full flex flex-col box-border">
    <mat-card class="p-0 flex flex-col flex-1 overflow-hidden border-none">
      <mat-card-content class="px-6 py-0">
        <hxp-account-details-overview></hxp-account-details-overview>
      </mat-card-content>
      <mat-card-content class="px-6 py-0 h-full">
        <hxp-account-details-subscriptions></hxp-account-details-subscriptions>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
