import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ContentCannotBeViewedView } from './views/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { HomeDashboardView } from './views/home-dashboard/home-dashboard.view';
import { UnauthorizedView } from './views/unauthorized/unauthorized.view';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeDashboardView, pathMatch: 'full' },
      {
        path: 'identity',
        loadChildren: () => import('./views/identity/identity.routes').then((m) => m.identityRoutes),
      },
      { path: 'account', loadChildren: () => import('./views/account/account.routes').then((m) => m.accountRoutes) },

      // for backward compatibility, and to prevent breaking old links/bookmarks for consumers
      { path: 'users', redirectTo: 'identity/users' },
      { path: 'user-groups', redirectTo: 'identity/user-groups' },
      { path: 'external-applications', redirectTo: 'identity/external-applications' },
      { path: 'identity-providers', redirectTo: 'identity/identity-providers' },
      { path: 'account-details', redirectTo: 'account/account-details' },
      { path: 'account-access', redirectTo: 'account/account-access' },
      { path: 'audit', redirectTo: 'account/audit' },
      { path: 'environment/:environmentId/apps', redirectTo: 'account/environment/:environmentId/apps' },
      { path: 'environment/:environmentId/subscriptions', redirectTo: 'account/environment/:environmentId/subscriptions' },
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedView,
  },
  {
    path: '**',
    component: ContentCannotBeViewedView,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
