import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { Flags } from '../../models/feature-flag';

@Injectable()
export class FeatureFlagsOverrideService {
  static OVERRIDDEN_FLAGS_STORAGE_KEY = 'overridden_flags';

  private readonly _overriddenFlagsSub$ = new BehaviorSubject<Flags>({});
  readonly overriddenFlags$ = this._overriddenFlagsSub$.asObservable();

  constructor(private readonly _storage: Storage) {
    this._syncStorageWithSteams();
  }

  overrideFlag(flagKey: string, value: boolean) {
    const flags = R.assoc(flagKey, value, this._overriddenFlagsSub$.value);
    this._updateStorageAndSteam(flags);
  }

  deleteOverrideFlag(flagKey: string) {
    const flags = R.dissoc(flagKey, this._overriddenFlagsSub$.value);
    this._updateStorageAndSteam(flags);
  }

  getFlagValue(flagKey: string): boolean | undefined {
    return this._overriddenFlagsSub$.value[flagKey];
  }

  getFlagValues() {
    return this._overriddenFlagsSub$.value;
  }

  getStoredFlags(): { [flagKey: string]: boolean } {
    return JSON.parse(this._storage.getItem(FeatureFlagsOverrideService.OVERRIDDEN_FLAGS_STORAGE_KEY) ?? '{}');
  }

  private updateLocalStorage(flags: Flags) {
    this._storage.setItem(FeatureFlagsOverrideService.OVERRIDDEN_FLAGS_STORAGE_KEY, JSON.stringify(flags));
  }

  private _updateStorageAndSteam(flags: Flags) {
    this._overriddenFlagsSub$.next(flags);
    this.updateLocalStorage(flags);
  }

  private _syncStorageWithSteams() {
    this._syncStoredFlagsWithSteam();
  }

  private _addFlagToSteam(flagKey: string, value: boolean) {
    const flags = R.assoc(flagKey, value, this._overriddenFlagsSub$.value);
    this._overriddenFlagsSub$.next(flags);
  }

  private _syncStoredFlagsWithSteam() {
    const storedOverriddenFlag = this.getStoredFlags();
    for (const [flagKey, value] of Object.entries(storedOverriddenFlag)) {
      this._addFlagToSteam(flagKey, value);
    }
  }
}
