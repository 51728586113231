import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { FeatureFlagDirective, KernelComponentsModule } from '@hxp/kernel';
import { HyDialogModule, HyFeedbackBannerModule, HyMaterialModule, HyToastModule, HyTranslateModule } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { AddInternalUserDialog } from '../../../../components/add-internal-user-dialog/add-internal-user.dialog';
import { DeactivateUserBannerComponent } from './deactivate-user/deactivate-user-banner/deactivate-user-banner.component';
import { DeactivateUserDialog } from './deactivate-user/deactivate-user-dialog/deactivate-user.dialog';
import { DeactivateUserComponent } from './deactivate-user/deactivate-user.component';
import { ReactivateUserDialog } from './reactivate-user-dialog/reactivate-user.dialog';
import { UnlockUserDialog } from './unlock-user/unlock-user-dialog/unlock-user.dialog';
import { UnlockUserBannerComponent } from './unlock-user/unlock-user/unlock-user-banner.component';
import { UserOperationsComponent } from './user-operations/user-operations.component';

@NgModule({
  declarations: [
    UserOperationsComponent,
    DeactivateUserComponent,
    DeactivateUserDialog,
    UnlockUserBannerComponent,
    UnlockUserDialog,
    DeactivateUserBannerComponent,
    ReactivateUserDialog,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    RouterModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDividerModule,
    FeatureFlagDirective,
    HyDialogModule,
    HyFeedbackBannerModule,
    HyMaterialModule,
    HyToastModule,
    FormsModule,
    ReactiveFormsModule,
    HyTranslateModule,
    AddInternalUserDialog,
    KernelComponentsModule,
    TranslocoModule,
  ],
  exports: [
    UserOperationsComponent,
    DeactivateUserComponent,
    DeactivateUserDialog,
    UnlockUserBannerComponent,
    UnlockUserDialog,
    DeactivateUserBannerComponent,
  ],
})
export class UserOperationsModule {}
