import { Component } from '@angular/core';

import { filterOutTemplateApps } from '@hxp/admin/applications/utils';
import { SubscriptionsForAccountGQL, SubscriptionsForAccountQuery, SubscriptionStatus } from '@hxp/graphql';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type SubscriptionsFromQuery = SubscriptionsForAccountQuery['currentUser']['homeAccount']['account']['subscriptions'];
interface EnvironmentIdToSubscriptionMap {
  [environmentId: string]: SubscriptionsFromQuery;
}

@Component({
  selector: 'hxp-admin-subscription-cards',
  templateUrl: './subscription-cards.component.html',
  styleUrls: ['./subscription-cards.component.scss'],
})
export class SubscriptionCardsComponent {
  subscriptionsByEnvironment$: Observable<EnvironmentIdToSubscriptionMap>;

  constructor(subscriptionsQuery: SubscriptionsForAccountGQL) {
    this.subscriptionsByEnvironment$ = subscriptionsQuery.watch().valueChanges.pipe(
      filter((subscriptionsQueryResult) => !!subscriptionsQueryResult.data),
      map((subscriptionQueryResult) => {
        return subscriptionQueryResult.data.currentUser.homeAccount.account.subscriptions.reduce(
          (subscriptionsByEnvironment, subscription) => {
            // Will not show unassigned subscriptions or subscriptions not in an active status
            if (subscription.environment && subscription.status === SubscriptionStatus.Active) {
              if (!subscriptionsByEnvironment[subscription.environment.id]) {
                subscriptionsByEnvironment[subscription.environment.id] = [];
              }

              const subscribedApps = filterOutTemplateApps(subscription.subscribedApps);

              subscriptionsByEnvironment[subscription.environment.id].push({
                ...subscription,
                subscribedApps,
              });
            }

            return subscriptionsByEnvironment;
          },
          {} as EnvironmentIdToSubscriptionMap,
        );
      }),
    );
  }
}
