import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { HyDialogModule, HyFeedbackBannerModule, HyMaterialFormFieldModule, HyMaterialIconModule } from '@hyland/ui';
import { Subject, combineLatest, switchMap, take, takeUntil, tap } from 'rxjs';
import { FeatureFlagContainer } from '../../models/feature-flag';
import { FeatureFlagsOverrideService } from '../../services/feature-flags-override/feature-flags-override.service';
import { FeatureFlagService, ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN } from '../../services/feature-flag/feature-flag.service';
import { FeatureFlagFormTypeDirective } from './flag-types-fields/feature-flag-form-type.directive';

interface FlagTableRow {
  readonly flagKey: string;
  readonly value: boolean;
  readonly type: 'boolean';
}

interface FlagFormGroup {
  readonly flagKey: FormControl<FlagTableRow['flagKey']>;
  readonly value: FormControl<FlagTableRow['value']>;
  readonly type: FormControl<FlagTableRow['type']>;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    FeatureFlagFormTypeDirective,
    ReactiveFormsModule,
    HyMaterialIconModule,
    HyMaterialFormFieldModule,
    HyDialogModule,
    HyFeedbackBannerModule,
  ],
  templateUrl: './feature-flags-override.dialog.html',
  styleUrls: ['./feature-flags-override.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagsOverrideDialog implements OnInit, OnDestroy {
  private readonly _unsubscribe$ = new Subject<void>();
  alreadyExists = false;

  readonly form = this._fb.group({
    flags: this._fb.array<FormGroup<FlagFormGroup>>([]),
  });

  private _originalFlagsValues: FeatureFlagContainer = {};

  get flags() {
    return this.form?.controls.flags;
  }

  constructor(
    private readonly _featureFlagsOverrideService: FeatureFlagsOverrideService,
    private readonly _featureFlagsService: FeatureFlagService,
    private readonly _fb: NonNullableFormBuilder,
    private readonly _cdr: ChangeDetectorRef,
    @Inject(ALLOWED_OVERRIDE_FEATURE_FLAGS_TOKEN) private readonly _featureFlagsToken: string[],
  ) {}

  ngOnInit(): void {
    this._createFormFromStoredFlags();
    this._listenForFlagsChanges();

    this._featureFlagsService.getAllFlags(this._featureFlagsToken).subscribe((flags) => {
      this._originalFlagsValues = flags;
      this._addMissingFlags(flags, this.flags.controls);

      this._cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getOriginalFlagValue(flagKey: string) {
    return this._originalFlagsValues[flagKey];
  }

  isOverridenFlag(flagKey: string) {
    return this._featureFlagsOverrideService.getFlagValue(flagKey) !== undefined;
  }

  removeFlagOverride(flagKey: string) {
    this.flags.controls.forEach((flag) => {
      if (flag.controls.flagKey.value === flagKey) {
        flag.controls.value.setValue(this.getOriginalFlagValue(flag.controls.flagKey.value));
      }
    });

    this._featureFlagsOverrideService.deleteOverrideFlag(flagKey);
  }

  private _updateTable(flags: FlagTableRow[]) {
    this._setupFormArray(flags);
  }

  private _setupFormArray(flags: FlagTableRow[]) {
    this.flags.clear();

    flags.forEach((flag) => {
      const group = this._fb.group(flag);
      this.flags.push(group);
    });
  }

  private _createFormFromStoredFlags() {
    this._featureFlagsOverrideService.overriddenFlags$
      .pipe(
        take(1),
        tap((flags) => {
          const rowFlags = Object.entries(flags).map(([flagKey, value]) => ({
            flagKey,
            value,
            type: typeof value as FlagTableRow['type'],
          }));

          this._updateTable(rowFlags);
        }),
      )
      .subscribe();
  }

  private _addFlagToFormArray(flag: FlagTableRow) {
    const group = this._fb.group(flag);
    this.flags.push(group);
  }

  private _listenForFlagsChanges() {
    this.flags.valueChanges
      .pipe(
        switchMap(() => {
          return combineLatest(
            this.flags.controls.map((flag) =>
              flag.controls.value.valueChanges.pipe(
                tap((value) => {
                  if (flag.value.value !== value) {
                    this._featureFlagsOverrideService.overrideFlag(flag.controls.flagKey.value, value);
                  }
                }),
              ),
            ),
          );
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private _addMissingFlags(newFlags: FeatureFlagContainer, existingFlags: Array<FormGroup<FlagFormGroup>>) {
    const existingFlagNames = new Set(existingFlags.map((flag) => flag.controls.flagKey.value));

    Object.keys(newFlags).forEach((flag) => {
      if (!existingFlagNames.has(flag)) {
        const defaultFlag: FlagTableRow = {
          flagKey: flag,
          type: 'boolean',
          value: newFlags[flag],
        };

        this._addFlagToFormArray(defaultFlag);
      }
    });
  }
}
