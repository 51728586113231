import { Injectable } from '@angular/core';
import { SwitcherAppsGQL, SwitcherAppsQuery } from '@hxp/graphql';
import { HyAuthService } from '@hyland/ui';
import { LinkCollection } from '@hyland/ui-shell';
import { BehaviorSubject, filter, map, switchMap, take, tap } from 'rxjs';

type AccountApps = SwitcherAppsQuery['currentUser']['accountApps'];

@Injectable({
  providedIn: 'root',
})
export class AppsSwitcherService {
  private readonly accountAppsSub$ = new BehaviorSubject<LinkCollection[]>([]);
  accountApps$ = this.accountAppsSub$.asObservable();

  constructor(private readonly authService: HyAuthService, private readonly _switcherAppsGQL: SwitcherAppsGQL) {
    this.waitForAuthentication();
  }

  waitForAuthentication() {
    this.authService.authenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => this._fetchAccountApps()),
        map((accountApps) => this._convertAccountAppsToLinksCollection(accountApps)),
        tap((linkCollection) => {
          if (linkCollection.links?.length) {
            this.accountAppsSub$.next([linkCollection]);
          }
        }),
      )
      .subscribe();
  }

  private _fetchAccountApps() {
    return this._switcherAppsGQL.fetch().pipe(map((res) => res.data.currentUser.accountApps));
  }

  private _convertAccountAppsToLinksCollection(accountApps: AccountApps): LinkCollection {
    const linkCollection: LinkCollection = {
      links: accountApps.map((accountApp) => ({
        label: accountApp.app.localizedName,
        url: accountApp.launchUrl,
      })),
    };

    return linkCollection;
  }
}
