import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HyGhostModule } from '@hyland/ui';

@Component({
  selector: 'hxp-kernel-form-field-ghost',
  templateUrl: './form-field-ghost.component.html',
  styleUrls: ['./form-field-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, HyGhostModule],
})
export class FormFieldGhostComponent {
  @Input()
  hideGhost = true;
}
