import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserEditInput } from '@hxp/graphql';
import { DisplayUserGroup } from '@hxp/nucleus';
import { HyDualListModule, HyGhostModule } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';
import * as R from 'ramda';
import { UserEditInputGroups } from './user-edit-input-groups';

@Component({
  selector: 'app-add-user-to-groups',
  templateUrl: './add-user-to-groups.component.html',
  styleUrls: ['./add-user-to-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, NgIf, HyDualListModule, HyGhostModule],
})
export class AddUserToGroupsComponent {
  @Input()
  isLoading!: boolean;

  @Input()
  unassignedGroups!: DisplayUserGroup[] | null;

  @Input()
  set userGroups(value: DisplayUserGroup[] | null) {
    this.preselectedUserGroups = value ? R.clone(value) : [];
  }
  preselectedUserGroups: DisplayUserGroup[] = [];

  @Output()
  assignedGroupsChanges = new EventEmitter<UserEditInputGroups>();

  onSelectedItemsChanged(selectedUsers: unknown[]) {
    const userInputChange = this._getEditedUserInputGroups(selectedUsers as readonly DisplayUserGroup[], this.preselectedUserGroups);

    this.assignedGroupsChanges.emit(userInputChange);
  }

  private _getEditedUserInputGroups(
    selected: readonly DisplayUserGroup[],
    initialUserGroups: readonly DisplayUserGroup[],
  ): UserEditInputGroups {
    const removeFromGroups: UserEditInput['userInput']['removeFromGroups'] = initialUserGroups
      .filter((group) => !selected.map((value) => value.id).includes(group.id))
      .map((group) => group.id);

    const addToGroups: UserEditInput['userInput']['addToGroups'] = selected
      .filter((group) => !initialUserGroups.map((value) => value.id).includes(group.id))
      .map((group) => group.id);

    return {
      addToGroups,
      removeFromGroups,
    };
  }

  trackById(_index: number, item: DisplayUserGroup): string {
    return item.id;
  }
}
