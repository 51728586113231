<div *transloco="let t; read: 'users.account-access.manage-role-user-groups-dialog'">
  <hy-dialog
    class="dialog"
    #dialog
    confirmLabel="{{ t('confirm-button-text') }}"
    dismissLabel="{{ t('dismiss-button-text') }}"
    (confirmed)="dialog.close(selectedList)"
    (dismissed)="dialog.close()"
    [header]="data.roleName + ' ' + t('header')"
  >
    <div class="grid grid-cols-2 gap-6 mb-4">
      <hy-search-input
        placeholder="{{ t('search-input-placeholder-text') }}"
        ariaLabel="{{ t('search-input-aria-text') }}"
        (search)="_onSearch($event)"
        (clear)="_onClear()"
        (valueChange)="searchValue$.next($event)"
      >
      </hy-search-input>
    </div>

    <div class="dual-list-wrapper h-full">
      <hy-dual-list
        *ngIf="!isLoading; else ghost"
        class="dual-list-component"
        availableListLabel="{{ t('available-users-list-label') }}"
        selectedListLabel="{{ t('selected-users-list-label') }}"
        [trackBy]="trackById"
        [compact]="false"
        [selectableItems]="filteredUserGroups"
        [value]="selectedList"
        (valueChange)="onSelectedItemsChanged($event)"
      >
        <ng-container *hyDualListItem="let dataItem">
          <div class="dual-list-truncate">{{ dataItem.displayName }}</div>
        </ng-container>
      </hy-dual-list>
      <ng-template #ghost>
        <div class="dual-list-component grid grid-cols-2 gap-6">
          <hy-ghost-block class="ghost-block-container h-full"></hy-ghost-block>
          <hy-ghost-block class="ghost-block-container h-full"></hy-ghost-block>
        </div>
      </ng-template>
    </div>
  </hy-dialog>
</div>
