import { FocusKeyManager } from '@angular/cdk/a11y';
import { NgIf } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, HostListener, Input, QueryList } from '@angular/core';
import { HyGhostModule } from '@hyland/ui';
import { MatListGhostLoaderComponent } from '../../ghost/mat-list-ghost-loader/mat-list-ghost-loader.component';
import { KeyboardNavListItemComponent } from './components/keyboard-nav-list-item/keyboard-nav-list-item.component';

interface ObjectWithId {
  readonly id: string;
}
@Component({
  selector: 'hxp-kernel-keyboard-nav-list',
  templateUrl: './keyboard-nav-list.component.html',
  styleUrls: ['./keyboard-nav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, HyGhostModule, MatListGhostLoaderComponent],
})
export class KeyboardNavListComponent implements AfterContentInit {
  // when user change the route for example by using mouse, or access it directly with some ID,
  // we need to update the keyboardEventsManager value as well,
  // as by default, its only changes when using keyboard

  @Input()
  get selectedRouteID() {
    return this._selectedRouteID;
  }
  set selectedRouteID(value: string | undefined) {
    this._selectedRouteID = value;
    this.updateActiveItem();
  }
  private _selectedRouteID?: string;

  // when user change the selected group using mouse, we need to update the keyboardEventsManager value as well,
  // as by default, its only changes when using keyboard

  @Input()
  get list() {
    return this._list;
  }
  set list(list: readonly ObjectWithId[] | undefined) {
    this._list = list;
    this.updateActiveItem();
  }
  private _list?: readonly ObjectWithId[];

  @Input() loading = false;

  @ContentChildren(KeyboardNavListItemComponent)
  listItems!: QueryList<KeyboardNavListItemComponent>;

  keyboardEventsManager?: FocusKeyManager<KeyboardNavListItemComponent>;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.keyboardEventsManager?.onKeydown(event);
  }

  ngAfterContentInit() {
    this.keyboardEventsManager = new FocusKeyManager(this.listItems).withWrap().withTypeAhead();

    // we need to address the NG0100: Expression has changed after it was checked issue.
    // as setting correct active item based on route id, need to be picked up by the next change detection cycle

    setTimeout(() => {
      this.updateActiveItem();
    }, 0);
  }

  updateActiveItem() {
    // when we can't find active item in list or when we don't have any selected navigation item, set it to 0
    // so the list can be navigate to via tab keyboard, and will select first item on the list.
    const fallbackFocusIndex = 0;

    const selectedUserGroupIndex = this.list?.findIndex((g) => g.id === this.selectedRouteID) || fallbackFocusIndex;
    const activeUserGroupIndex = selectedUserGroupIndex !== -1 ? selectedUserGroupIndex : fallbackFocusIndex;

    this.keyboardEventsManager?.updateActiveItem(activeUserGroupIndex);

    if (this.keyboardEventsManager?.activeItem) {
      // to correctly update tabIndex for the element as when the navigation was placed in component using OnPush
      // ChangeDetectionStrategy the item was not refreshed
      this.keyboardEventsManager.activeItem.markForCheck();
    }
  }
}
