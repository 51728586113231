<mat-card appearance="raised" *ngIf="elevate; else flatContent" class="mat-elevation-z12 flex flex-col">
  <ng-container *ngTemplateOutlet="flatContent"></ng-container>
</mat-card>

<ng-template #flatContent>
  <div
    class="flex flex-col items-center justify-center text-center px-6 min-h-[350px]"
    *transloco="let t; read: 'nucleus.page-does-not-exist'"
  >
    <p class="mat-headline-1" data-testid="page-does-not-exist-header">{{ t('header') }}</p>
    <p class="mat-body" data-testid="page-does-not-exist-body">{{ t('message') }}</p>

    <div class="mt-10" *ngIf="(text$ | async) as text">
      <ng-container *ngIf="link">
        <button data-testid="go-to-link-button" mat-flat-button color="primary" [routerLink]="link">{{ text }}</button>
      </ng-container>
    </div>
  </div>
</ng-template>
