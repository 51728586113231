import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoleAssignUserGroupsForAccountInput } from '@hxp/graphql';
import { HyContentListDataSource, HyTranslateService, configureHyDialogOptions } from '@hyland/ui';
import { Subject } from 'rxjs';
import { DisplayUserGroup } from '../../types/display-user-group';
import { filterDatasource } from '../filter';
import { ManageRoleUserGroupsDialog } from '../manage-role-user-groups-dialog/manage-role-user-groups.dialog';
import { RoleMappingContentListItem, RoleMappings } from '../role-mappings';

@Component({
  selector: 'hxp-admin-users-role-mappings',
  templateUrl: './role-mappings.component.html',
  styleUrls: ['./role-mappings.component.scss'],
})
export class RoleMappingsComponent implements OnDestroy, OnChanges {
  private readonly _dialog = inject(MatDialog);
  private readonly _translate = inject(HyTranslateService);

  @Input()
  roleMappings!: readonly RoleMappings[];

  @Input() loading = false;

  @Input()
  header!: string;

  @Input({ required: true })
  appKey?: string;

  @Output()
  roleEdit = new EventEmitter<RoleAssignUserGroupsForAccountInput>();

  datasource!: HyContentListDataSource<RoleMappingContentListItem>;

  private readonly _unsubscribe$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roleMappings.currentValue) {
      const roles = changes.roleMappings.currentValue.map((r: RoleMappings) => {
        return {
          key: r.role.key,
          name: r.role.name,
          userGroupsLabel: this.getGroups(r.userGroups),
          userGroups: r.userGroups,
        } as RoleMappingContentListItem;
      }) as RoleMappingContentListItem[];

      this.datasource = new HyContentListDataSource(roles);
      this.datasource.filterPredicate = filterDatasource;
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  editGroupsForARole(roleKey: string, roleName: string, roleUserGroups: DisplayUserGroup[]) {
    this._dialog
      .open(
        ManageRoleUserGroupsDialog,
        configureHyDialogOptions({
          ariaLabel: this._translate.get('users.account-access.manage-role-user-groups-dialog.dialog-label'),
          width: 'auto',
          data: {
            roleName,
            userGroupsForRole: roleUserGroups,
          },
        }),
      )
      .afterClosed()
      .subscribe((selected) => {
        if (!selected) {
          return;
        }
        const userRightsInput = this.getEditedUserRightsInput(roleKey, selected);
        this.roleEdit.emit(userRightsInput);
      });
  }

  getEditedUserRightsInput(roleKey: string, selectedUserGroups: DisplayUserGroup[]): RoleAssignUserGroupsForAccountInput {
    const roleMappingData = this.datasource.data.find(
      (data: RoleMappingContentListItem) => data.key === roleKey,
    ) as RoleMappingContentListItem;
    const userGroupIdsInRole = roleMappingData.userGroups.map((userGroup) => userGroup.id) ?? [];
    const selectedUserGroupIds = selectedUserGroups.map((userGroup) => userGroup.id);
    const addUserGroupIds = selectedUserGroupIds.filter((groupId) => !userGroupIdsInRole.includes(groupId));
    const removeUserGroupIds = userGroupIdsInRole.filter((groupId) => !selectedUserGroupIds.includes(groupId));

    return {
      appKey: this.appKey ?? '',
      roleKey,
      addUserGroupIds,
      removeUserGroupIds,
    };
  }

  getGroups(item: DisplayUserGroup[]) {
    return item.map((item) => item.displayName).join(', ');
  }
}
