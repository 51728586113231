<ng-container *transloco="let t; read: 'users.users-components.user-form'">
  <hxp-kernel-submitting-overlay [submitting]="submitting">
    <form data-testid="user-info-form" [formGroup]="form">
      <hxp-kernel-form-field-ghost [hideGhost]="!isLoading">
        <mat-form-field hyFormField class="w-full">
          <mat-label>{{ t('user-name-input-label') }}</mat-label>
          <input matInput (blur)="onBlur(form.controls.username, 'username')" formControlName="username" data-testid="username-input" />
          <mat-error>{{ getErrorMessage(form.controls.username) }}</mat-error>
          <mat-progress-bar
            class="w-auto -mx-3"
            [ngClass]="{ invisible: !form.controls.username.pending }"
            mode="indeterminate"
          ></mat-progress-bar>
        </mat-form-field>
      </hxp-kernel-form-field-ghost>

      <hxp-kernel-form-field-ghost [hideGhost]="!isLoading">
        <mat-form-field hyFormField hxpKernelInputAutofocus class="w-full">
          <mat-label>{{ t('name-input-label') }}</mat-label>
          <input matInput formControlName="firstname" data-testid="name-input" />
          <mat-error>{{ getErrorMessage(form.controls.firstname) }}</mat-error>
        </mat-form-field>
      </hxp-kernel-form-field-ghost>

      <hxp-kernel-form-field-ghost [hideGhost]="!isLoading">
        <mat-form-field hyFormField class="w-full">
          <mat-label>{{ t('last-name-input-label') }}</mat-label>
          <input matInput formControlName="lastname" data-testid="last-name-input" />
          <mat-error>{{ getErrorMessage(form.controls.lastname) }}</mat-error>
        </mat-form-field>
      </hxp-kernel-form-field-ghost>

      <hxp-kernel-form-field-ghost [hideGhost]="!isLoading">
        <mat-form-field hyFormField class="w-full">
          <mat-label>{{ t('email-input-label') }}</mat-label>
          <input
            (blur)="onBlur(form.controls.email, 'email')"
            matInput
            placeholder="example@domain.com"
            formControlName="email"
            data-testid="email-input"
          />
          <mat-error>{{ getErrorMessage(form.controls.email) }}</mat-error>
          <mat-progress-bar
            class="w-auto -mx-3"
            [ngClass]="{ invisible: !form.controls.email.pending }"
            mode="indeterminate"
          ></mat-progress-bar>
        </mat-form-field>
        <mat-form-field hyFormField class="w-full" *ngIf="confirmEmail">
          <mat-label>{{ t('confirm-email-input-label') }}</mat-label>
          <input required matInput placeholder="example@domain.com" formControlName="confirmEmail" data-testid="confirm-email-input" />
          <mat-error>{{ getErrorMessage(form.controls.confirmEmail) }}</mat-error>
        </mat-form-field>
      </hxp-kernel-form-field-ghost>
    </form>
  </hxp-kernel-submitting-overlay>
</ng-container>
