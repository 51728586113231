import { Injectable } from '@angular/core';
import { AccountIdGQL } from '@hxp/graphql';
import { HyAuthService } from '@hyland/ui';
import { Observable, catchError, map, of, shareReplay, switchMap } from 'rxjs';
import { EnvironmentContextService } from '../../../services/environment-context/environment-context.service';
import { FlagAdditionalContext, FlagContext } from '../../models/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsContextBuilderService {
  private readonly _accountId$ = this._account.fetch().pipe(
    map((res) => res.data.currentUser.homeAccount.account.id),
    catchError(() => of('')),
    shareReplay(1),
  );

  constructor(
    private readonly _environment: EnvironmentContextService,
    private readonly _account: AccountIdGQL,
    private readonly _authService: HyAuthService,
  ) {}

  getFlagContext(additionalContext?: FlagAdditionalContext): Observable<FlagContext> {
    const typeContext$ = additionalContext?.type === 'environment' ? this._getEnvBaseContext() : this._getAccountBaseContext();

    return typeContext$.pipe(
      map((typeContext) => ({
        ...(additionalContext ?? {}),
        ...typeContext,
      })),
    );
  }

  private _getEnvBaseContext(): Observable<FlagContext> {
    return this._environment.selectedEnvironment$.pipe(map(this._createEnvBaseContext));
  }

  private _getAccountBaseContext(): Observable<FlagContext> {
    return this._authService.authenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return this._accountId$.pipe(map(this._createAccountBaseContext));
        }

        return of(this._emptyContextForUnauthenticatedUsers());
      }),
    );
  }

  private _createAccountBaseContext(accountId: string): FlagContext {
    const context: FlagContext = {
      account: accountId,
      type: 'account',
      key: `hxp-acc-${accountId}`,
    };

    return context;
  }

  private _emptyContextForUnauthenticatedUsers(): FlagContext {
    const context: FlagContext = {
      key: 'hxp',
    };

    return context;
  }

  private _createEnvBaseContext(environmentId: string): FlagContext {
    const context: FlagContext = {
      environment: environmentId,
      type: 'environment',
      key: `hxp-env-${environmentId}`,
    };

    return context;
  }
}
