<ng-container *transloco="let t; read: 'nucleus.applications.components.app-user-rights'">
  <div class="p-6 pl-0 flex flex-col h-full box-border">
    <hxp-admin-users-role-mappings
      class="h-full"
      *ngIf="userRights$ | async as rights; else ghost"
      [roleMappings]="rights.currentUser.homeAccount.account.subscribedApp?.environmentRoles || []"
      [appKey]="appKey"
      (roleEdit)="editUserRights($event)"
    >
    </hxp-admin-users-role-mappings>
    <ng-template #ghost>
      <hy-ghost-block class="h-full"></hy-ghost-block>
    </ng-template>
  </div>
</ng-container>
