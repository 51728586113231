import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fieldMaxLength = {
  UserName: 256,
  FirstName: 255,
  LastName: 255,
  Email: 254,
  ClientName: 200,
  ClaimTypeRestrictions: 255,
};

export const fieldPattern = {
  UserName: '^[A-Za-z0-9-+@_.]+$',
  Email: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$',
  // match any string that has at least one non-whitespace character anywhere in the string.
  ClientName: '.*\\S.*',
};

export class FormValidators {
  /**
   * @description
   * Confirms if given input field matches control which we used this Validator on.
   * If the given input fields does not match, it returns 'fieldsMatch' error
   * @param fieldNameToMatchWith Name of control which we want to check if has the same value.
   * @param ignoreCase Specifies whether comparing with case sensitivity or not
   */
  static fieldsMatch(fieldNameToMatchWith: string, ignoreCase = true): ValidatorFn {
    let fieldControl: AbstractControl | null;
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      fieldControl = control.parent.get(fieldNameToMatchWith);
      const fieldValue = ignoreCase ? control.value.toLocaleLowerCase() : control.value;
      const fieldValueToMatchWith = ignoreCase ? fieldControl?.value.toLocaleLowerCase() : fieldControl?.value;

      if (fieldValue !== fieldValueToMatchWith) {
        return {
          fieldsMatch: true,
        };
      }

      return null;
    };
  }
}
