<a
  [attr.data-testid]="testId"
  [attr.tabindex]="isActiveFocusIndex ? '0' : '-1'"
  [routerLink]="[routerLink]"
  routerLinkActive="hy-list-item--active"
  matRipple
  hyListItem
  mat-list-item
>
  <span matLine>{{ displayName }}</span>
  <ng-content></ng-content>
</a>
