import { InjectionToken } from '@angular/core';
import { PendoConfig, defaultPendoConfig } from './pendo-analytics/pendo';

export interface Analytics {
  initialize: () => void | Promise<void>;
}

export interface AnalyticsConfig {
  readonly enable: boolean;
  readonly pendoConfig: PendoConfig;
}

export const defaultAnalyticsConfig: AnalyticsConfig = {
  enable: false,
  pendoConfig: defaultPendoConfig,
};

export const ANALYTICS_CONFIG = new InjectionToken<AnalyticsConfig>('ANALYTICS_CONFIG');
export const ANALYTICS_SERVICE = new InjectionToken<Analytics>('ANALYTICS_SERVICE');
