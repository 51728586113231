<ng-container *transloco="let t; read: 'nucleus.applications.components.application-overview'">
  <div class="p-6 pl-0 flex flex-col">
    <ng-container *ngIf="overview$ | appWithLoading | async as overviewQuery">
      <hxp-kernel-spaceless-progress-bar [isLoading]="overviewQuery.loading"></hxp-kernel-spaceless-progress-bar>
      <ng-container *ngIf="overviewQuery.value as overview">
        <section class="mb-6 flex flex-col">
          <header class="details-header">
            <h2 class="mat-subtitle-1">{{ t('header') }}</h2>
          </header>
          <div class="details-form grid grid-cols-1 gap-4">
            <mat-form-field class="input" hyFormField>
              <mat-label>{{ t('app-name-label') }}</mat-label>
              <input
                matInput
                aria-readonly="true"
                [ngModel]="overview.currentUser.homeAccount.account.subscribedApp?.app?.localizedName"
                readonly
                type="text"
                data-testid="app-details-app-name"
              />
            </mat-form-field>
            <mat-form-field class="input" hyFormField>
              <mat-label>{{ t('app-description-label') }}</mat-label>
              <textarea
                class="descriptiton"
                readonly
                aria-readonly="true"
                matInput
                [cdkTextareaAutosize]="true"
                [ngModel]="overview.currentUser.homeAccount.account.subscribedApp?.app?.localizedDescription"
                data-testid="app-details-app-description"
              ></textarea>
            </mat-form-field>
          </div>
        </section>
        <section class="mb-6 flex flex-col">
          <header class="details-header">
            <h3 class="mat-subtitle-1">{{ t('subscription-information-header') }}</h3>
          </header>
          <div class="grid grid-cols-1 gap-4 mb-6">
            <mat-form-field class="input" hyFormField>
              <mat-label>{{ t('subscription-name-label') }}</mat-label>
              <input
                aria-readonly="true"
                matInput
                [ngModel]="overview.currentUser.homeAccount.account.subscribedApp?.subscription?.title"
                readonly
                type="text"
                data-testid="app-details-subscription-name"
              />
            </mat-form-field>
          </div>
        </section>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
