import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SpacelessProgressBarComponent } from './components/spaceless-progress-bar/spaceless-progress-bar.component';
import { WithLoadingPipe } from './pipes/with-loading/with-loading.pipe';

@NgModule({
  declarations: [SpacelessProgressBarComponent],
  imports: [CommonModule, MatProgressBarModule, WithLoadingPipe],
  exports: [WithLoadingPipe, MatProgressBarModule, SpacelessProgressBarComponent],
})
export class SharedLoadersModule {}
