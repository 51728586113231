import { Component, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { HyDialog } from '@hyland/ui';
import { NewSubscriptionDialogService } from './new-subscription-dialog.service';

@Component({
  selector: 'app-new-subscription-dialog',
  templateUrl: './new-subscription.dialog.html',
  styleUrls: ['./new-subscription.dialog.scss'],
})
export class NewSubscriptionDialog {
  @ViewChild(HyDialog, { static: false })
  dialog!: HyDialog;

  @ViewChild(MatCheckbox, { static: false })
  doNotShowAgain!: MatCheckbox;

  constructor(private readonly _router: Router, private readonly _newSubscriptionDialogService: NewSubscriptionDialogService) {}

  onConfirmed() {
    this._checkIfShouldHideMessages();
    void this._router.navigate(['account', 'account-details', 'subscriptions']);
    this.dialog.close();
  }

  onDismissed() {
    this._checkIfShouldHideMessages();
    this.dialog.close();
  }

  private _checkIfShouldHideMessages() {
    if (this.doNotShowAgain.checked) {
      this._newSubscriptionDialogService.ignoreNewSubscriptions();
    }
  }
}
