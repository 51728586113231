import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HyAuthLoginCallbackParams, HyAuthLoginParam, HyAuthService, HyAuthState } from '@hyland/ui';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FakeAuthService extends HyAuthService {
  authenticated = true;
  idpUnreachable$ = new Subject<Error>();

  private readonly _authenticatedSub$ = new BehaviorSubject<boolean>(true);
  authenticated$ = this._authenticatedSub$.asObservable();

  constructor(private readonly _router: Router) {
    super();
  }

  getIdToken(): string | undefined {
    return 'abc';
  }

  getAccessToken(): string | undefined {
    return 'xyz';
  }

  getUserProfile<T>(): Promise<T> {
    return Promise.resolve({} as T);
  }

  login(_params?: HyAuthLoginParam | undefined): void | Promise<void> {
    this._authenticatedSub$.next(true);
    this.authenticated = true;

    void this._router.navigateByUrl('/view/authentication-confirmation');
    return Promise.resolve();
  }

  loginCallback(_params?: HyAuthLoginCallbackParams | undefined): Promise<HyAuthState<Record<string, unknown>> | undefined> {
    return Promise.resolve({ route: '/' });
  }

  logout(): void | Promise<void> {
    this._authenticatedSub$.next(false);
    this.authenticated = false;

    void this._router.navigateByUrl('/view/unauthenticated');

    return Promise.resolve();
  }
}
